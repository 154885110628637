import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dCenter" }
const _hoisted_2 = { class: "dCenter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_select, {
      modelValue: _ctx.inputSelected,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputSelected) = $event)),
        _ctx.inputValue
      ],
      filterable: false,
      options: _ctx.options,
      class: "vSelect",
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      multiple: _ctx.multiple,
      label: _ctx.optionLabel,
      onSearch: _ctx.onSearch
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "no-options", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.noOption), 1 /* TEXT */)
        ]),
        _renderSlot(_ctx.$slots, "option", {}, () => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.option.name), 1 /* TEXT */)
        ]),
        _renderSlot(_ctx.$slots, "selected-option", {}, () => [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.option.name), 1 /* TEXT */)
        ])
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["modelValue", "options", "placeholder", "disabled", "multiple", "label", "onUpdate:modelValue", "onSearch"])
  ]))
}