import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "value", "disabled"]

import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'RadioBox',
  props: /*@__PURE__*/_mergeModels({
    name: {},
    value: {},
    checked: { type: Boolean },
    disabled: { type: Boolean },
    class: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['update:radioValue'], ["update:modelValue"]),
  setup(__props: any) {

const radioValue = _useModel(__props, "modelValue")
const error = ref(false)

const props = __props

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  let name = 'form__radiobox-label'
  if (props.class !== null) {
    name += ` ${props.class}`
  }
  if (error.value) {
    name += ' form__input--field-error'
  }
  return name
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(className.value)
  }, [
    _withDirectives(_createElementVNode("input", {
      name: _ctx.name,
      class: "form__radio",
      type: "radio",
      value: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((radioValue).value = $event)),
      disabled: _ctx.disabled
    }, null, 8 /* PROPS */, _hoisted_1), [
      [_vModelRadio, radioValue.value]
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})