import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ScrollArea" }
const _hoisted_2 = { class: "Header" }
const _hoisted_3 = { class: "HeaderIcon" }
const _hoisted_4 = { class: "HeaderText" }
const _hoisted_5 = { class: "List" }
const _hoisted_6 = {
  key: 0,
  class: "PathCell PathCell-Heading"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: _ctx.animationType }, {
      default: _withCtx(() => [
        (_ctx.currentSheet)
          ? (_openBlock(), _createElementBlock("div", {
              key: _ctx.currentSheet.title + _ctx.currentIndex,
              class: "Sheet"
            }, [
              _createElementVNode("header", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.isBackable)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "jirafficon jirafficon-arrow-left",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.currentSheet.title), 1 /* TEXT */),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "HeaderIcon" }, null, -1 /* HOISTED */))
              ]),
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentSheet.list, (cell, i) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: cell.label + i,
                    class: "Cell"
                  }, [
                    (cell.heading)
                      ? (_openBlock(), _createElementBlock("h3", _hoisted_6, _toDisplayString(cell.label), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          class: "PathCell",
                          onClick: 
                (e) => {
                  _ctx.selected(cell, e)
                }
              
                        }, [
                          _createElementVNode("label", null, _toDisplayString(cell.label), 1 /* TEXT */),
                          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "jirafficon jirafficon-arrow-right" }, null, -1 /* HOISTED */))
                        ], 8 /* PROPS */, _hoisted_7))
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["name"])
  ]))
}