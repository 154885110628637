import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "convert-coupon-modal" }
const _hoisted_2 = {
  key: 0,
  class: "convert-coupon-modal__top-area"
}
const _hoisted_3 = { class: "convert-coupon-modal__thumbnail-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "convert-coupon-modal__descriptions" }
const _hoisted_6 = { class: "convert-coupon-modal__prize-title" }
const _hoisted_7 = {
  key: 1,
  class: "convert-coupon-modal__converted-coupon-success"
}
const _hoisted_8 = { class: "convert-coupon-modal__bottom-area" }
const _hoisted_9 = {
  key: 0,
  class: "convert-coupon-modal__coupon-area-wrapper"
}
const _hoisted_10 = { class: "convert-coupon-modal__coupon-area" }
const _hoisted_11 = { class: "convert-coupon-modal__coupon-name-area" }
const _hoisted_12 = { class: "convert-coupon-modal__coupon-image-wrapper" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "convert-coupon-modal__coupon-name" }
const _hoisted_15 = {
  key: 0,
  class: "convert-coupon-modal__coupon-condition-price"
}
const _hoisted_16 = { class: "convert-coupon-modal__coupon-expired-at" }
const _hoisted_17 = {
  key: 0,
  class: "convert-coupon-modal__coupon-area"
}
const _hoisted_18 = { class: "convert-coupon-modal__coupon-name-area" }
const _hoisted_19 = { class: "convert-coupon-modal__coupon-name" }
const _hoisted_20 = { class: "convert-coupon-modal__coupon-expired-at" }
const _hoisted_21 = {
  key: 1,
  class: "convert-coupon-modal__coupon-convert-rare-attention"
}
const _hoisted_22 = {
  key: 1,
  class: "convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted"
}
const _hoisted_23 = { class: "convert-coupon-modal__coupon-name-area" }
const _hoisted_24 = { class: "convert-coupon-modal__coupon-name" }
const _hoisted_25 = { class: "convert-coupon-modal__coupon-condition-price" }
const _hoisted_26 = { class: "convert-coupon-modal__coupon-expired-at" }
const _hoisted_27 = {
  key: 2,
  class: "convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted"
}
const _hoisted_28 = { class: "convert-coupon-modal__coupon-name-area" }
const _hoisted_29 = { class: "convert-coupon-modal__coupon-name" }
const _hoisted_30 = { class: "convert-coupon-modal__converted-coupon-description" }
const _hoisted_31 = {
  key: 2,
  class: "convert-coupon-modal__button-area"
}
const _hoisted_32 = ["disabled"]
const _hoisted_33 = {
  key: 3,
  class: "convert-coupon-modal__button-area"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.existsCoupon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "convert-coupon-modal__thumbnail",
              src: _ctx.lotteryResultPrize.thumbnail_url
            }, null, 8 /* PROPS */, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.lotteryResultPrize.prize_title), 1 /* TEXT */),
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "convert-coupon-modal__convert-coupon-question" }, " を下記に変換しますか？ ", -1 /* HOISTED */))
          ])
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_7, " 変換しました！ ")),
    _createElementVNode("div", _hoisted_8, [
      (!_ctx.existsCoupon)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("img", {
                    class: "convert-coupon-modal__coupon-image",
                    src: _ctx.iconImage
                  }, null, 8 /* PROPS */, _hoisted_13)
                ]),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.couponName(_ctx.lotteryResultPrize.convertable_coupon_discount_name)), 1 /* TEXT */)
              ]),
              (_ctx.lotteryResultPrize.convertable_coupon_condition_price)
                ? (_openBlock(), _createElementBlock("p", _hoisted_15, " (" + _toDisplayString(_ctx.couponConditionPrice) + ") ", 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.couponExpiredAt), 1 /* TEXT */)
            ]),
            (_ctx.existsConvertablePoints)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "convert-coupon-modal__points-icon-wrapper" }, [
                      _createElementVNode("i", { class: "fs-xl mr-s jirafficon jirafficon-point" })
                    ], -1 /* HOISTED */)),
                    _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.format(_ctx.lotteryResultPrize.convertable_points)) + " ポイント ", 1 /* TEXT */)
                  ]),
                  _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.pointsExpiredAt), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "convert-coupon-modal__coupon-convert-attention" }, " ※一度交換すると元に戻せません。 ", -1 /* HOISTED */)),
            (_ctx.isRare)
              ? (_openBlock(), _createElementBlock("p", _hoisted_21, " 貴重なカードですが本当に交換しても良いですか？ "))
              : _createCommentVNode("v-if", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "convert-coupon-modal__converted-icon-wrapper" }, [
                _createElementVNode("i", { class: "fs-3xl mr-xs jirafficon jirafficon-check-circle-filled" })
              ], -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.couponName(_ctx.coupon.name)), 1 /* TEXT */)
            ]),
            _createElementVNode("p", _hoisted_25, " (" + _toDisplayString(_ctx.convertedCouponConditionPrice) + ") ", 1 /* TEXT */),
            _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.convertedCouponExpiredAt), 1 /* TEXT */)
          ])),
      (_ctx.existsPoints)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            _createElementVNode("div", _hoisted_28, [
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "convert-coupon-modal__converted-icon-wrapper" }, [
                _createElementVNode("i", { class: "fs-3xl mr-xs jirafficon jirafficon-check-circle-filled" })
              ], -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.format(_ctx.point_detail.points)) + " ポイント ", 1 /* TEXT */)
            ]),
            _createElementVNode("p", _hoisted_30, " 所有ポイント: " + _toDisplayString(_ctx.format(_ctx.personal.points)) + " ポイント ", 1 /* TEXT */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (!_ctx.existsCoupon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createElementVNode("button", {
            class: "convert-coupon-modal__cancel-button btn btn--small btn--ghost-primary",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickCancelButton && _ctx.onClickCancelButton(...args)))
          }, " やめておく "),
          _createElementVNode("button", {
            class: _normalizeClass([
          'convert-coupon-modal__convert-coupon-button btn btn--small',
          _ctx.isConvertedCoupon ? 'btn--disabled' : 'btn--primary-light',
        ]),
            disabled: _ctx.isConvertedCoupon,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickConvertButton && _ctx.onClickConvertButton(...args)))
          }, " 変換する ", 10 /* CLASS, PROPS */, _hoisted_32)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_33, [
          _createElementVNode("button", {
            class: "convert-coupon-modal__close-button btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickCancelButton && _ctx.onClickCancelButton(...args)))
          }, " 閉じる ")
        ]))
  ]))
}