import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["area-label"]
const _hoisted_2 = ["value", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "form__input py-m",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSelectorModal && _ctx.showSelectorModal(...args))),
      "area-label": _ctx.name
    }, _toDisplayString(_ctx.selectedLabel), 9 /* TEXT, PROPS */, _hoisted_1),
    _createElementVNode("input", {
      type: "hidden",
      value: _ctx.selectedOption ? _ctx.selectedOption.value : null,
      name: _ctx.name
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}