import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "shortcodes-blogs-search-items" }
const _hoisted_2 = { class: "shortcodes-blogs-search-items__container" }
const _hoisted_3 = { class: "shortcodes-blogs-search-items__title" }
const _hoisted_4 = { class: "shortcodes-blogs-search-items__results-container" }
const _hoisted_5 = {
  key: 0,
  class: "shortcodes-blogs-search-items__notfound-container"
}
const _hoisted_6 = { class: "shortcodes-blogs-search-items__items-container item-list" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "item-list__thumbnail" }
const _hoisted_9 = { class: "item-list__thumbnail-content" }
const _hoisted_10 = ["alt", "data-src", "src"]
const _hoisted_11 = {
  key: 0,
  class: "item-list__sold-icon"
}
const _hoisted_12 = { class: "item-list__item-name" }
const _hoisted_13 = { class: "item-list__price-box" }
const _hoisted_14 = { class: "item-list__price-box--price" }
const _hoisted_15 = { class: "item-list__price-box--favorite-number" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 0,
  class: "item-list__badge"
}
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 0,
  class: "shortcodes-blogs-search-items__more-button-container"
}
const _hoisted_20 = ["href"]
const _hoisted_21 = { class: "shortcodes-blogs-search-items__more-button-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "jirafficon jirafficon-loupe" }, null, -1 /* HOISTED */)),
        _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.created && _ctx.items.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "shortcodes-blogs-search-items__notfound-image" }, null, -1 /* HOISTED */)),
              _createElementVNode("div", null, _toDisplayString(_ctx.noResultsLabel), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.displayed_id,
              class: "item-list__box item-list__box--shortcode-blog-search-items"
            }, [
              _createElementVNode("a", {
                class: "item-list__link",
                href: _ctx.itemPath(item)
              }, [
                _createElementVNode("figure", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      height: "auto",
                      width: "100%",
                      alt: item.name,
                      "data-src": _ctx.thumbnailUrl(item),
                      class: "lozad lazyload lazyload--wrap",
                      src: _ctx.thumbnailUrl(item),
                      "data-loaded": "true"
                    }, null, 8 /* PROPS */, _hoisted_10)
                  ]),
                  (item.status != 'presented')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_12, _toDisplayString(item.name), 1 /* TEXT */),
                _createElementVNode("ul", _hoisted_13, [
                  _createElementVNode("li", _hoisted_14, _toDisplayString(_ctx.numberToCurrency(item.price)), 1 /* TEXT */),
                  _createElementVNode("li", _hoisted_15, [
                    _createElementVNode("img", { src: _ctx.iconStarImagePath }, null, 8 /* PROPS */, _hoisted_16),
                    _createTextVNode(_toDisplayString(item.check_count), 1 /* TEXT */)
                  ])
                ]),
                (item.owner.user_type === 'business')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      _createElementVNode("img", { src: _ctx.iconBadgeItemShopPath }, null, 8 /* PROPS */, _hoisted_18)
                    ]))
                  : _createCommentVNode("v-if", true)
              ], 8 /* PROPS */, _hoisted_7)
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      (_ctx.created)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("a", {
              href: _ctx.moreButtonPath(),
              class: "shortcodes-blogs-search-items__more-button"
            }, [
              _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.moreButtonLabel()), 1 /* TEXT */),
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "shortcodes-blogs-search-items__more-button-icon jirafficon jirafficon-arrow-right inline-icon" }, null, -1 /* HOISTED */))
            ], 8 /* PROPS */, _hoisted_20)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}