import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form__label" }
const _hoisted_2 = {
  key: 0,
  class: "form__required"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.required)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " ※必須 "))
      : _createCommentVNode("v-if", true)
  ]))
}