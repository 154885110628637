<template>
  <div class="shortcodes-blogs-search-items">
    <div class="shortcodes-blogs-search-items__container">
      <div class="shortcodes-blogs-search-items__title">
        <i class="jirafficon jirafficon-loupe" />{{ title }}
      </div>
      <div class="shortcodes-blogs-search-items__results-container">
        <div
          v-if="created && items.length < 1"
          class="shortcodes-blogs-search-items__notfound-container"
        >
          <div class="shortcodes-blogs-search-items__notfound-image" />
          <div>{{ noResultsLabel }}</div>
        </div>
        <div class="shortcodes-blogs-search-items__items-container item-list">
          <div
            v-for="item in items"
            :key="item.displayed_id"
            class="item-list__box item-list__box--shortcode-blog-search-items"
          >
            <a class="item-list__link" :href="itemPath(item)">
              <figure class="item-list__thumbnail">
                <div class="item-list__thumbnail-content">
                  <img
                    height="auto"
                    width="100%"
                    :alt="item.name"
                    :data-src="thumbnailUrl(item)"
                    class="lozad lazyload lazyload--wrap"
                    :src="thumbnailUrl(item)"
                    data-loaded="true"
                  />
                </div>
                <div
                  v-if="item.status != 'presented'"
                  class="item-list__sold-icon"
                />
              </figure>
              <div class="item-list__item-name">{{ item.name }}</div>
              <ul class="item-list__price-box">
                <li class="item-list__price-box--price">
                  {{ numberToCurrency(item.price) }}
                </li>
                <li class="item-list__price-box--favorite-number">
                  <img :src="iconStarImagePath" />{{ item.check_count }}
                </li>
              </ul>
              <div
                v-if="item.owner.user_type === 'business'"
                class="item-list__badge"
              >
                <img :src="iconBadgeItemShopPath" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="created"
        class="shortcodes-blogs-search-items__more-button-container"
      >
        <a
          :href="moreButtonPath()"
          class="shortcodes-blogs-search-items__more-button"
        >
          <div class="shortcodes-blogs-search-items__more-button-label">
            {{ moreButtonLabel() }}
          </div>
          <i
            class="shortcodes-blogs-search-items__more-button-icon jirafficon jirafficon-arrow-right inline-icon"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import noimage from 'images/noimage-card.svg'
import iconBadgeItemShop from 'images/icon_badge_item_shop.png'
import iconStarOn from 'images/icon_star_on.png'
import { defineComponent } from 'vue'
import { AxiosResponse } from 'axios'
import { searchItems } from '~/api/items/search'
import numberFormattable from '~/components/mixins/numberFormattable'
import paramsSerializer from '~/api/paramsSerializer'

interface Data {
  items: Model.Item[]
  chunkedItems: Model.Item[][]
  created: boolean
}

interface Methods {
  moreButtonLabel(): string
  moreButtonPath(): string
  thumbnailUrl(item: Model.Item): string
  itemPath(item: Model.Item): string
}

interface Computed {
  iconStarImagePath: string
  iconBadgeItemShopPath: string
}

interface CustomAttribute {
  name: string
  values: string[]
}

interface Props {
  searchParameters: {
    forms_search_items: {
      keyword: string
      series_id: string
      brand_id: string
      goods_id: string
      from_price: string
      to_price: string
      sort: string
      page: string
    }
    item: {
      custom_attributes: CustomAttribute[]
    }
  }
  title: string
  moreLabel: string
  noResultsLabel: string
  otherKeywordLabel: string
}

export default defineComponent<Data, Methods, Computed, Props>({
  mixins: [numberFormattable],
  props: {
    searchParameters: { type: Object },
    title: { type: String },
    moreLabel: { type: String },
    noResultsLabel: { type: String },
    otherKeywordLabel: { type: String },
  },
  data() {
    return {
      items: [],
      chunkedItems: [],
      created: false,
    }
  },
  computed: {
    iconStarImagePath(): string {
      return iconStarOn
    },
    iconBadgeItemShopPath(): string {
      return iconBadgeItemShop
    },
  },
  async created() {
    searchItems(this.searchParameters)
      .then((response: AxiosResponse<Api.Items.Search.Response>) => {
        this.items = response.data.items.slice(0, 8)
      })
      .finally(() => {
        this.created = true
      })
  },
  methods: {
    moreButtonLabel(): string {
      if (this.items.length > 0) {
        return this.moreLabel
      }
      return this.otherKeywordLabel
    },
    moreButtonPath(): string {
      return `/items/search?${paramsSerializer(this.searchParameters)}`
    },
    thumbnailUrl(item: Model.Item): string {
      return item.thumbnail_url || noimage
    },
    itemPath(item: Model.Item): string {
      return `/items/${item.displayed_id}`
    },
  },
})
</script>
