<template>
  <div class="lottery-result-prize-card">
    <div
      v-if="!isMypage && !isOripaCard"
      class="lottery-result-prize-card__coupon"
    >
      <div class="lottery-result-prize-card__thumbnail-wrapper">
        <img class="lottery-result-prize-card__thumbnail" :src="couponImage" />
      </div>
      <div class="lottery-result-prize-card__info">
        <div class="lottery-result-prize-card__info-text-area">
          <p class="lottery-result-prize-card__prize-title">
            {{ couponName }}
          </p>
          <p class="lottery-result-prize-card__target-category">
            {{ targetCategory }}
          </p>
          <p class="lottery-result-prize-card__expired-at">
            {{ couponExpiredAt }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="['lottery-result-prize-card__oripa-card', prizeCardInnerClass]"
    >
      <div class="lottery-result-prize-card__info-wrapper">
        <div class="lottery-result-prize-card__grade-wrapper">
          <img class="lottery-result-prize-card__grade" :src="gradeImage" />
        </div>
        <div class="lottery-result-prize-card__thumbnail-wrapper">
          <img
            class="lottery-result-prize-card__thumbnail"
            :src="lotteryResultPrize.thumbnail_url"
          />
        </div>
        <div class="lottery-result-prize-card__info">
          <div
            class="lottery-result-prize-card__info-text-area lottery-result-prize-card__info-text-area--card"
          >
            <p class="lottery-result-prize-card__lottery-title">
              {{ lotteryResultPrize.lottery_title }}
            </p>
            <p class="lottery-result-prize-card__prize-title">
              {{ lotteryResultPrize.prize_title }}
            </p>
            <div
              v-if="isConvertable && isShippable"
              class="lottery-result-prize-card__date-area"
            >
              <p class="lottery-result-prize-card__expired-at">
                {{ statusText }}
              </p>
              <p
                v-if="isStored"
                class="lottery-result-prize-card__date-in-words"
              >
                {{ shippingRequestDueDateInWords }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isStored" class="lottery-result-prize-card__button-area">
        <div
          v-if="isShippable"
          class="lottery-result-prize-card__transit-request-shipping-btn-wrapper"
        >
          <button
            :class="[
              'lottery-result-prize-card__transit-request-shipping-btn btn btn--small btn--full',
              transitRequestShippingBtnClass,
            ]"
            :disabled="!isStored"
            @click="onTransitRequestShippingPage"
          >
            <i class="fs-m mr-xs jirafficon jirafficon-track" />
            発送申請
          </button>
        </div>
        <div
          v-if="isConvertable"
          class="lottery-result-prize-card__open-convert-coupon-btn-wrapper"
        >
          <button
            :class="[
              'lottery-result-prize-card__open-convert-coupon-btn btn btn--small btn--full',
              openConvertCouponBtnClass,
            ]"
            :disabled="!isStored"
            @click="onOpenConvertCouponModal"
          >
            クーポン
            <span
              v-if="existsConvertablePoints"
              class="lottery-result-prize-card__open-convert-points-label"
            >
              <i class="fs-s mr-xs ml-xs jirafficon jirafficon-plus" />
              ポイント
            </span>
            に変換
          </button>
          <div class="lottery-result-prize-card__open-convert-payouts">
            <span class="lottery-result-prize-card__open-convert-payout-coupon">
              <i class="fs-l mr-xs jirafficon jirafficon-coupon" />
              {{ convertPayoutCoupon }}
            </span>
            <span
              v-if="existsConvertablePoints"
              class="lottery-result-prize-card__open-convert-payout-points"
            >
              <i
                class="fs-m mr-xs ml-xs text-gray jirafficon jirafficon-plus"
              />
              <i class="fs-m mr-xs jirafficon jirafficon-point" />
              {{ format(lotteryResultPrize.convertable_points) }}
              ポイント
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import firstGradeImage from 'images/oripa/icon_first_grade.png'
import secondGradeImage from 'images/oripa/icon_second_grade.png'
import thirdGradeImage from 'images/oripa/icon_third_grade.png'
import fourthGradeImage from 'images/oripa/icon_fourth_grade.png'
import fifthGradeImage from 'images/oripa/icon_fifth_grade.png'
import couponImage from 'images/oripa/thumbnail_coupon.jpg'
import numberFormattable from '~/components/mixins/numberFormattable'

interface Methods {
  onOpenConvertCouponModal(): void
  onTransitRequestShippingPage(): void
}

interface Computed {
  isOripaCard: boolean
  isStored: boolean
  isShippingRequested: boolean
  isShipped: boolean
  isConvertable: boolean
  isConverted: boolean
  isConvertedToPoints: boolean
  prizeCardInnerClass: { [key: string]: boolean }
  openConvertCouponBtnClass: { [key: string]: boolean }
  transitRequestShippingBtnClass: { [key: string]: boolean }
  statusText: string
  gradeImage: string
  couponImage: string
  targetCategory: string
  couponName: string
  shippingRequestDueDateInWords: string
  couponExpiredAt: string
  convertPayoutCoupon: string
  existsConvertablePoints: boolean
  isShippable: boolean
}

interface Props {
  lotteryResultPrize: Model.LotteryResultPrize
  isMypage: boolean
}

type PrizeCardInnerClasses =
  | 'lottery-result-prize-card__oripa-card--around-border'
  | 'lottery-result-prize-card__oripa-card--disabled'
type openConvertCouponBtnClasses = 'btn--primary-light' | 'btn--disabled'
type transitRequestShippingBtnClasses = 'btn--ghost-primary' | 'btn--disabled'

export default defineComponent<
  Record<string, unknown>,
  Methods,
  Computed,
  Props
>({
  mixins: [numberFormattable],
  props: {
    lotteryResultPrize: {
      type: Object,
      required: true,
    },
    isMypage: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    isOripaCard(): boolean {
      return this.lotteryResultPrize.prizable_type === 'Oripa::Card'
    },
    isStored(): boolean {
      return this.lotteryResultPrize.status === 'stored'
    },
    isShippingRequested(): boolean {
      return this.lotteryResultPrize.status === 'shipping_requested'
    },
    isShipped(): boolean {
      return this.lotteryResultPrize.status === 'shipped'
    },
    isConvertable(): boolean {
      return this.lotteryResultPrize.is_convertable
    },
    isConverted(): boolean {
      return this.lotteryResultPrize.status === 'converted'
    },
    isConvertedToPoints(): boolean {
      return this.lotteryResultPrize.status === 'converted_to_points'
    },
    isShippable(): boolean {
      return this.lotteryResultPrize.is_shipping_requestable
    },
    prizeCardInnerClass(): { [key in PrizeCardInnerClasses]: boolean } {
      return {
        'lottery-result-prize-card__oripa-card--around-border': this.isStored,
        'lottery-result-prize-card__oripa-card--disabled':
          this.isShipped || this.isConverted || this.isConvertedToPoints,
      }
    },
    openConvertCouponBtnClass(): {
      [key in openConvertCouponBtnClasses]: boolean
    } {
      return {
        'btn--primary-light': this.isStored,
        'btn--disabled':
          this.isShipped ||
          this.isShippingRequested ||
          this.isConverted ||
          this.isConvertedToPoints,
      }
    },
    transitRequestShippingBtnClass(): {
      [key in transitRequestShippingBtnClasses]: boolean
    } {
      return {
        'btn--ghost-primary': this.isStored,
        'btn--disabled':
          this.isShipped ||
          this.isShippingRequested ||
          this.isConverted ||
          this.isConvertedToPoints,
      }
    },
    statusText(): string {
      let manufacturedText: string

      switch (this.lotteryResultPrize.status) {
        case 'stored':
          if (!this.lotteryResultPrize.shipping_request_due_date) {
            manufacturedText = `発送期限: なし`
          } else {
            manufacturedText = `発送期限: ${this.lotteryResultPrize.shipping_request_due_date} 23:59`
          }
          break
        case 'shipping_requested':
          manufacturedText = `発送申請済み（${this.lotteryResultPrize.shipping_requested_at}）`
          break
        case 'shipped':
          manufacturedText = `発送完了（${this.lotteryResultPrize.shipped_at}）`
          break
        case 'converted':
          manufacturedText = !this.lotteryResultPrize.convertable_points
            ? 'クーポン変換済み'
            : 'クーポン + ポイント変換済み'
          break
        case 'converted_to_points':
          manufacturedText = 'ポイント変換済み'
          break
        default:
          manufacturedText = ''
      }

      return manufacturedText
    },
    gradeImage(): string {
      switch (this.lotteryResultPrize.grade) {
        case 'first_winning':
          return firstGradeImage
        case 'second_winning':
          return secondGradeImage
        case 'third_winning':
          return thirdGradeImage
        case 'fourth_winning':
          return fourthGradeImage
        case 'fifth_winning':
          return fifthGradeImage
        default:
          return ''
      }
    },
    couponImage(): string {
      return couponImage
    },
    targetCategory(): string {
      if (
        !this.lotteryResultPrize.coupon_condition_goods_name &&
        !this.lotteryResultPrize.coupon_condition_brand_name &&
        !this.lotteryResultPrize.coupon_condition_series_name
      ) {
        return '対象カテゴリ: 全て'
      }

      const goods = this.lotteryResultPrize.coupon_condition_goods_name
        ? `${this.lotteryResultPrize.coupon_condition_goods_name} `
        : ''
      const brand = this.lotteryResultPrize.coupon_condition_brand_name
        ? `${this.lotteryResultPrize.coupon_condition_brand_name} `
        : ''
      const series = this.lotteryResultPrize.coupon_condition_series_name
        ? `${this.lotteryResultPrize.coupon_condition_series_name}`
        : ''

      return `対象カテゴリ: ${goods}${brand}${series}`.trimEnd()
    },
    couponName(): string {
      return `${this.lotteryResultPrize.prize_title}offクーポン`
    },
    shippingRequestDueDateInWords(): string {
      return `${this.lotteryResultPrize.shipping_request_due_date_in_words}`
    },
    couponExpiredAt(): string {
      if (this.lotteryResultPrize.coupon_expired_at) {
        return `有効期限: ${this.lotteryResultPrize.coupon_expired_at} 23:59`
      }

      return 'なし'
    },
    convertPayoutCoupon(): string {
      return `${this.lotteryResultPrize.convertable_coupon_discount_name}offクーポン`
    },
    existsConvertablePoints(): boolean {
      return !!this.lotteryResultPrize.convertable_points
    },
  },
  methods: {
    onOpenConvertCouponModal(): void {
      this.$emit('onOpenConvertCouponModal', this.lotteryResultPrize)
    },
    onTransitRequestShippingPage(): void {
      this.$emit('onTransitRequestShippingPage', this.lotteryResultPrize.id)
    },
  },
})
</script>

<style scoped lang="scss">
.lottery-result-prize-card {
  &__oripa-card {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: $space-LL;
    padding: $space-M;
    row-gap: $space-M;
    background-color: $co-white;
    box-shadow: $shadow-box;
    border-radius: $round;

    &--around-border {
      border: 2px solid $co-primary-light;
    }

    &--disabled {
      background-color: $co-light-gray;
    }
  }

  &__coupon {
    position: relative;
    display: flex;
    margin-bottom: $space-LL;
    padding: $space-M;
    background-color: $co-white;
    box-shadow: $shadow-box;
    border-radius: $round;
  }

  &__grade-wrapper {
    width: 40px;
    position: absolute;
    top: -12px;
    left: -10px;
  }

  &__grade {
    width: 100%;
  }

  &__thumbnail-wrapper {
    height: 58px;
    margin-right: $space-S;
    width: 58px;
  }

  &__thumbnail {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &__info-wrapper {
    display: flex;
  }

  &__info {
    flex-grow: 1;
  }

  &__lottery-title {
    font-size: $font-size-XS;
    color: $co-dark-gray;
  }

  &__prize-title {
    flex: 1;
    font-weight: bold;
    margin-bottom: $space-XS;
  }

  &__target-category {
    font-size: $font-size-XS;
  }

  &__date-area {
    display: flex;
    gap: $space-S;
  }

  &__expired-at {
    font-size: $font-size-XS;
    color: $co-dark-gray;
  }

  &__date-in-words {
    font-size: $font-size-XS;
    color: $co-red;
  }

  &__button-area {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__transit-request-shipping-btn-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: $space-S;
    width: 100%;
  }

  &__transit-request-shipping-btn {
    margin-top: 0;
    max-width: 420px;
    min-width: 100px;

    &--disabled {
      background-color: $co-btn-disabled;
    }
  }
  &__open-convert-coupon-btn-wrapper {
    align-items: center;
    background-color: $co-bg;
    flex-direction: column;
    display: flex;
    padding: $space-S $space-XS;
    width: 100%;
  }

  &__open-convert-coupon-btn {
    max-width: 420px;
    min-width: 100px;

    &--disabled {
      background-color: $co-btn-disabled;
    }
  }

  &__open-convert-points-label {
    display: flex;
    align-items: center;
  }

  &__open-convert-payouts {
    display: flex;
    align-items: center;
    font-size: $font-size-S;
    margin-top: $space-XXS;
  }

  &__open-convert-payout-coupon {
    align-items: center;
    color: $co-main-light;
    display: flex;
    font-weight: 700;
  }

  &__open-convert-payout-points {
    align-items: center;
    color: $co-red;
    display: flex;
    font-weight: 700;
  }
}
</style>
