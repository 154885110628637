import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name"]

import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'SelectBox',
  props: /*@__PURE__*/_mergeModels({
    selectorWrapperClass: {},
    selectorClass: {},
    selectorId: {},
    name: {}
  }, {
    "modelValue": { type: String },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const value = _useModel(__props, "modelValue")
const error = ref(false)

const props = __props

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  const names = ['form__input']
  if (props.selectorClass !== undefined) {
    names.push(props.selectorClass)
  }
  if (error.value) {
    names.push('form__input--field-error')
  }
  return names.join(' ')
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const wrapperClassName = computed((): string => {
  const names = ['form__input--select-wrapper']
  if (props.selectorWrapperClass !== undefined) {
    names.push(props.selectorWrapperClass)
  }
  return names.join(' ')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(wrapperClassName.value)
  }, [
    _withDirectives(_createElementVNode("select", {
      id: _ctx.selectorId,
      name: _ctx.name,
      class: _normalizeClass(className.value),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 10 /* CLASS, PROPS */, _hoisted_1), [
      [_vModelSelect, value.value]
    ])
  ], 2 /* CLASS */))
}
}

})