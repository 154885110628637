import { Controller } from 'stimulus'
import { createApp } from 'vue'
import { execCopy } from '~/core/execCopy'
import SnackBar from '~/components/atoms/SnackBar.vue'

export default class extends Controller {
  static targets = ['source', 'button']

  sourceTarget?: HTMLInputElement

  buttonTarget?: HTMLElement

  copy(): void {
    const data = this.buttonTarget?.dataset
    if (data === undefined) {
      return
    }
    const { clipboardSource, succeedLabel } = data
    if (clipboardSource === undefined) {
      return
    }
    if (execCopy(clipboardSource)) {
      const app = createApp(SnackBar, {
        title: succeedLabel || 'URLをコピーしました',
        variant: 'success',
        icon: 'check-circle-filled',
      })
      app.mount('.js-snackbar')
    }
  }
}
