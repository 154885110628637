<template>
  <div class="ScrollArea">
    <transition :name="animationType">
      <div
        v-if="currentSheet"
        :key="currentSheet.title + currentIndex"
        class="Sheet"
      >
        <header class="Header">
          <div class="HeaderIcon">
            <span
              v-if="isBackable"
              class="jirafficon jirafficon-arrow-left"
              @click="back"
            />
          </div>
          <h2 class="HeaderText">
            {{ currentSheet.title }}
          </h2>
          <div class="HeaderIcon" />
        </header>
        <ul class="List">
          <li
            v-for="(cell, i) in currentSheet.list"
            :key="cell.label + i"
            class="Cell"
          >
            <h3 v-if="cell.heading" class="PathCell PathCell-Heading">
              {{ cell.label }}
            </h3>
            <a
              v-else
              class="PathCell"
              @click="
                (e) => {
                  selected(cell, e)
                }
              "
            >
              <label>{{ cell.label }}</label>
              <span class="jirafficon jirafficon-arrow-right" />
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface SelectableCell<Value = unknown> {
  label: string
  isLeaf: boolean
  value: Value
}

interface HeadingCell {
  heading: true
  label: string
}

interface Sheet<Value = unknown> {
  title: string
  list: Array<SelectableCell<Value> | HeadingCell>
}

interface Data<Value = unknown> {
  sheets: Sheet<Value>[]
  prevIndex: number
  currentIndex: number
  animationType: 'forward' | 'back'
}

interface Methods<Value = unknown> {
  pushSheet(sheet: Sheet<Value>): void
  back(): void
  selected(cell: SelectableCell<Value>, e: Event): void
}

interface Computed<Value = unknown> {
  currentSheet: Sheet<Value>
  isBackable: boolean
}

interface Props<Value = unknown> {
  onSelected(
    cell: SelectableCell<Value>,
    push: (sheet: Sheet<Value>) => void
  ): void
  onBack?(): void
}

export default defineComponent<Data, Methods, Computed, Props>({
  props: {
    onSelected: {
      type: Function,
      required: true,
    },
    onBack: {
      type: Function,
      required: false,
    },
  },
  data(): Data {
    return {
      animationType: 'forward',
      prevIndex: -1,
      currentIndex: -1,
      sheets: [],
    }
  },
  computed: {
    currentSheet(): Sheet {
      return this.sheets[this.currentIndex]
    },
    isBackable(): boolean {
      return this.currentIndex >= 1
    },
  },
  methods: {
    pushSheet(sheet: Sheet): void {
      this.animationType = 'forward'
      this.prevIndex = this.currentIndex
      this.sheets.push(sheet)
      this.currentIndex += 1
    },
    back(): void {
      this.animationType = 'back'
      this.prevIndex = this.currentIndex
      this.currentIndex -= 1
      this.sheets.pop()
      if (this.onBack) {
        this.onBack(this.currentSheet)
      }
    },
    selected(cell: SelectableCell, e: Event): void {
      e.preventDefault()
      this.onSelected(cell, this.pushSheet)
    },
  },
})
</script>

<style scoped lang="scss">
.Header {
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  align-items: center;
  background: $co-main;
  color: $co-white;
  padding: $space-S;
}
.HeaderText {
  font-size: $font-size-M;
}
.HeaderIcon {
  width: 50px;
  color: $co-white;
  font-size: $font-size-XL;
}

.ScrollArea {
  position: relative;
  min-height: 500px;
}

.Sheet {
  position: absolute;
  top: 0;
  width: 100%;
}

.List {
}

.Cell {
  border-bottom: 1px solid $co-border;
  background: $co-white;
}

.PathCell {
  display: flex;
  justify-content: space-between;
  color: $co-black;
  padding: $space-S $space-M;
}

.PathCell-Heading {
  background: $co-light-gray;
  font-size: $font-size-S;
  font-weight: normal;
}

.LeafCell {
  display: flex;
  justify-content: space-between;
}

.back-enter-active,
.back-leave-active,
.forward-enter-active,
.forward-leave-active {
  transition: transform 0.2s ease, opacity 0.2s ease-in;
}

.back-enter {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}

.back-leave-to {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
}

.forward-enter {
  opacity: 0;
  transform: translate3d(100px, 0, 0);
}
.forward-leave-to {
  opacity: 0;
  transform: translate3d(-100px, 0, 0);
}
</style>
