<template>
  <div class="item-estimates">
    <div class="item-estimates__row item-estimates__row-header">
      <div class="item-estimates__subject">
        <div>売上金</div>
        <div class="item-estimates__dd" aria-labelledby="売上金">
          {{ incomePrice() }}
        </div>
      </div>
    </div>
    <div class="item-estimates__row">
      <div class="item-estimates__details">
        <div>商品価格</div>
        <div class="item-estimates__dd">
          {{ sales() }}
        </div>
      </div>
      <div class="item-estimates__details">
        <div>販売手数料</div>
        <div class="item-estimates__dd">
          {{ chargeText() }}
        </div>
      </div>
    </div>
    <div class="item-estimates__row item-estimates__row-header">
      <div class="item-estimates__subject item-estimates__subject-description">
        <div>販売価格（購入者負担金額）</div>
        <div v-if="sellingPriceDescription" class="item-estimates__description">
          {{ sellingPriceDescription() }}
        </div>
        <div class="item-estimates__amount">
          {{ sellingPrice() }}
        </div>
      </div>
    </div>
    <div class="item-estimates__row">
      <div v-for="detail in sellingPriceDetails()" :key="detail">
        <div :key="detail.label" class="item-estimates__details">
          <div :key="detail.label">
            {{ detail.label }}
          </div>
          <div :key="detail.label + detail.value" class="item-estimates__dd">
            {{ detail.value }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="earningPointsDisplayable()"
      class="item-estimates__row item-estimates__row-header"
    >
      <div class="item-estimates__subject">
        <div>獲得ポイント</div>
      </div>
    </div>
    <div v-if="earningPointsDisplayable()" class="item-estimates__row">
      <div class="item-estimates__details">
        <div class="item-estimates__dd">
          {{ earningPointsText() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface LabelValue {
  label: string
  value: string
}

interface Estimate {
  subjects: LabelValue
  details?: Array<LabelValue>
}

interface Props {
  estimates: Array<Estimate>
}

interface Methods {
  sales(): string
  incomePrice(): string
  chargeText(): string
  sellingPrice(): string
  sellingPriceDetails(): { label: string; value: string }[]
  sellingPriceDescription(): string
  earningPointsText(): string
  earningPointsDisplayable(): boolean
}

export default defineComponent<
  Record<string, unknown>,
  Methods,
  Record<string, unknown>,
  Props
>({
  props: {
    estimates: { type: Array },
  },
  methods: {
    sales(): string {
      return this.estimates[0]?.details[0]?.value
    },
    incomePrice(): string {
      return this.estimates[0]?.subject?.value
    },
    chargeText(): string {
      return this.estimates[0]?.details[1]?.value
    },
    sellingPrice(): string {
      return this.estimates[1]?.subject.value
    },
    sellingPriceDetails(): [] {
      return this.estimates[1]?.details
    },
    sellingPriceDescription(): string {
      return this.estimates[1]?.description
    },
    earningPointsText(): string {
      return this.estimates[2]?.subject?.value
    },
    earningPointsDisplayable(): boolean {
      return this.earningPointsText()?.length > 0
    },
  },
})
</script>

<style scoped lang="scss">
.item-estimates {
  display: flex;
  flex-direction: column;
  font-size: $font-size-S;
  padding: 0 $space-L;
}

.item-estimates__row {
  border-top: 1px solid $co-border;
  display: flex;
  flex-direction: column;
  padding: $space-M 0;
}

.item-estimates__subject {
  display: flex;
  justify-content: space-between;
}

.item-estimates__subject-description {
  flex-direction: column;
}

.item-estimates__details {
  display: flex;
  justify-content: space-between;
}

.item-estimates__row-header {
  border-top: none;
  font-size: $font-size-M;
  font-weight: bold;
}

.item-estimates__description {
  font-size: $font-size-XXS;
  font-weight: normal;
  padding-top: $space-XS;
}

.item-estimates__amount {
  padding-top: $space-XS;
  text-align: right;
}

.item-estimates__dd {
  flex: 1;
  text-align: right;
}
</style>
