<template>
  <label class="form__checkbox-label">
    <input
      v-model="checkValue"
      :name="name"
      class="form__checkbox"
      type="checkbox"
      :value="value"
      :checked="checked"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkValue = defineModel()

defineProps<{
  name: string
  value: string
  checked?: boolean
}>()
</script>
