import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "snackbar"
}
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.body)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", null, [
          _createVNode(_TransitionGroup, {
            name: "snack",
            tag: "span"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("li", {
                key: _ctx.title,
                class: _normalizeClass('snack snack--' + _ctx.variant),
                "aria-label": "Snack Bar"
              }, [
                (_ctx.icon)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: _normalizeClass(`icon jirafficon jirafficon-${_ctx.icon}`),
                      "aria-label": _ctx.icon
                    }, null, 10 /* CLASS, PROPS */, _hoisted_2))
                  : _createCommentVNode("v-if", true),
                _createTextVNode(" " + _toDisplayString(_ctx.body) + " ", 1 /* TEXT */),
                _createElementVNode("i", {
                  class: "close-icon jirafficon jirafficon-close",
                  "aria-label": "閉じる",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.body = undefined))
                })
              ], 2 /* CLASS */))
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]))
    : _createCommentVNode("v-if", true)
}