<template>
  <input
    :class="className"
    :placeholder="placeholder"
    :type="type"
    :name="name"
    v-model="value"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const value = defineModel()
const error = ref(false)

const props = defineProps<{
  placeholder?: string
  type: string
  class?: string
  name: string
}>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  let name = 'form__input'
  if (props.class !== null) {
    name += ` ${props.class}`
  }
  if (error.value) {
    name += ' form__input--field-error'
  }
  return name
})
</script>
