import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = ["id", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", {
      class: _normalizeClass(_ctx.$style.Header)
    }, _cache[0] || (_cache[0] = [
      _createElementVNode("h2", null, "選択してください", -1 /* HOISTED */)
    ]), 2 /* CLASS */),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("li", {
          id: `posting_method_cell_${option.value}`,
          key: option.value,
          class: _normalizeClass(_ctx.$style.Cell),
          onClick: () => _ctx.onSelected(option)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.CellInner),
            role: "button"
          }, [
            _createTextVNode(_toDisplayString(option.label) + " ", 1 /* TEXT */),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.checkboxClassName(option))
            }, null, 2 /* CLASS */)
          ], 2 /* CLASS */)
        ], 10 /* CLASS, PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}