import { Controller } from 'stimulus'
import JustPurchasedModal from '~/components/organisms/trades/JustPurchasedModal.vue'
import showModalWithVue from '~/core/showModalWithVue'

export default class extends Controller {
  connect(): void {
    window.addEventListener('load', () => {
      this.showJustPurchasedModal(this.data.get('props'))
    })
  }

  showJustPurchasedModal(propsJson: string | null): void {
    if (!propsJson) return
    const props = JSON.parse(propsJson)

    showModalWithVue(JustPurchasedModal, 'fixed-size', {
      isAppAccess: props.isAppAccess,
      currentUserIsIos: props.currentUserIsIos,
      shareUrl: props.shareUrl,
      subText: props.subText,
      productImageUrl: props.productImageUrl,
    })
  }
}
