import { defineComponent } from 'vue'

const numberToCurrency = new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
})
const formatter = new Intl.NumberFormat('ja-JP')

export default defineComponent({
  methods: {
    numberToCurrency(num: number): string {
      return numberToCurrency.format(num)
    },
    format(num: number): string {
      return formatter.format(num)
    },
  },
})
