<template>
  <div>
    <div class="search-form">
      <form
        id="item_advanced_search"
        ref="searchForm"
        class="form"
        :action="actionPath"
        accept-charset="UTF-8"
        method="get"
      >
        <div v-if="owner" class="user-info">
          <input
            type="hidden"
            :value="owner.displayedId"
            name="forms_search_items[owner_displayed_id]"
          />
          <div class="user-info__id">
            {{ owner.sellerIdLabel }}: {{ owner.displayedId }}
          </div>
          <div class="user-info__container--small">
            <div class="user-info__icon--small">
              <img
                :src="owner.image || iconNoImageUrl"
                class="user-info__icon--small"
              />
            </div>
            <div class="user-info__detail--small">
              <div class="user-info__name--small">
                {{ owner.nickname }}
              </div>
              <div class="user-info__badges--small">
                <div v-if="owner.verified" class="tag tag--authorized">
                  <i class="jirafficon jirafficon-authorized" />
                  {{ owner.verified }}
                </div>
                <div v-if="owner.business" class="tag tag--business">
                  <img
                    :src="iconBusinessAccountUrl"
                    class="user-info__badges--purple"
                  />
                  {{ owner.business }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="from-group form__group--column form__group--first">
          <div class="form__label">
            <label>{{ keywordLabel }}</label>
          </div>
          <div class="form__control search-form__form-control">
            <input
              id="forms_search_items_keyword"
              v-model="keyword"
              :placeholder="keywordPlaceholder"
              class="form__input form__input--small form__input--full-width"
              type="search"
              name="forms_search_items[keyword]"
            />
          </div>
        </div>
        <div
          v-if="isPopup && hotTrendKeywords.length"
          class="from-group form__group--column"
        >
          <div class="form__label form__label--normal">
            <label>{{
              enLocale() ? 'Hot keywords' : 'ホットキーワード'
            }}</label>
          </div>
          <hot-trend-keyword
            :hot-trend-keywords="hotTrendKeywords"
            :search-by-hot-trend-keyword="searchByHotTrendKeyword"
          />
        </div>
        <div class="from-group form__group--column">
          <div class="form__group--column" aria-labelly="カテゴリの選択">
            <div class="form__label">
              <label>{{ goodsLabel }}</label>
            </div>
            <div class="search-form__form-control">
              <button
                type="button"
                :class="
                  goods
                    ? 'search-form__series-selector-text'
                    : 'search-form__series-selector-text search-form__series-selector-text--blank'
                "
                @click="showGoodsSelector"
              >
                {{ goods ? goods.name_i18n : '---' }}
              </button>
            </div>
            <input
              v-if="goods"
              type="hidden"
              name="forms_search_items[goods_id]"
              :value="goods.id"
            />
            <div v-if="brand" class="form__label">
              <label>{{ brandLabel }}</label>
            </div>
            <div v-if="brand" class="search-form__form-control">
              <button
                type="button"
                class="search-form__series-selector-text"
                @click="showGoodsSelector"
              >
                {{ brand ? brand.name_i18n : '---' }}
              </button>
            </div>
            <input
              v-if="brand"
              type="hidden"
              name="forms_search_items[brand_id]"
              :value="brand.id"
            />
            <div v-if="series" class="form__label">
              <label>{{ seriesLabel }}</label>
            </div>
            <div v-if="series" class="search-form__form-control">
              <button
                type="button"
                class="search-form__series-selector-text"
                @click="showGoodsSelector"
              >
                {{ series ? series.name_i18n : '---' }}
              </button>
            </div>
            <input
              v-if="series"
              type="hidden"
              name="forms_search_items[series_id]"
              :value="series.id"
            />
            <form-template-renderer
              v-if="formTemplate && isSelectedSneaker()"
              :template="formTemplate"
              :selected-attributes-param="selectedSize"
              selector-class="form__input form__input--small form__input--full-width form__input--padded"
              selector-wrapper-class="form__input-select-wrapper--full-width form__input-select-wrapper--icon-fixed form__input--small"
            />
          </div>
        </div>
        <div v-if="!isPopup" class="from-group form__group--column">
          <div class="form__label">
            <label>{{ priceLabel }}</label>
          </div>
          <div class="form__control search-form__form-control">
            <div class="search-form__double-slider">
              <div
                class="search-form__double-slider-track"
                :style="{ background: sliderTrackBackground }"
              />
              <input
                v-model="sliderMin"
                class="search-form__double-slider-input"
                type="range"
                min="0"
                max="32"
                :data-disable="disablePriceBar"
              />
              <input
                v-model="sliderMax"
                class="search-form__double-slider-input"
                type="range"
                min="0"
                max="32"
                :data-disable="disablePriceBar"
              />
            </div>
          </div>
          <div class="form__control search-form__form-control">
            <div class="search-form__input-price">
              <span class="search-form__currency-symbol">¥</span>
              <input
                id="forms_search_items_from_price"
                v-model="fromPrice"
                placeholder="min"
                class="form__input form__input--small"
                type="text"
                name="forms_search_items[from_price]"
              />
              <span class="search-form__partition">～</span>
              <input
                id="forms_search_items_to_price"
                v-model="toPrice"
                placeholder="max"
                class="form__input form__input--small"
                type="text"
                name="forms_search_items[to_price]"
              />
            </div>
          </div>
        </div>
        <div v-if="!isPopup" class="from-group form__group--column">
          <div class="form__label">
            <label>{{ qualityLabel }}</label>
          </div>
          <div class="form__control search-form__form-control">
            <div
              class="form__input--select-wrapper form__input-select-wrapper--full-width form__input--small"
            >
              <select
                id="forms_search_items_quality"
                v-model="quality"
                class="form__input form__input--small form__input--full-width form__input--select"
                name="forms_search_items[quality]"
              >
                <option value="">---</option>
                <option
                  v-for="quality in qualityOptions"
                  :key="quality.value"
                  :value="quality.value"
                >
                  {{ quality.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="!isPopup" class="from-group form__group--column">
          <div class="form__label">
            <label>{{ statusLabel }}</label>
          </div>
          <div class="form__control search-form__form-control">
            <div
              class="form__input--select-wrapper form__input-select-wrapper--full-width form__input--small"
            >
              <select
                id="forms_search_items_status"
                v-model="status"
                class="form__input form__input--small form__input--full-width form__input--select"
                name="forms_search_items[status]"
              >
                <option value="">---</option>
                <option
                  v-for="status in statusOptions"
                  :key="status.value"
                  :value="status.value"
                >
                  {{ status.label }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="!isPopup" class="from-group">
          <div class="form__control">
            <label
              class="form__checkbox-label form__checkbox-label--additional-condition"
            >
              <input
                id="forms_search_items_include_info"
                v-model="includeInfo"
                class="form__checkbox form__checkbox--align-left"
                type="checkbox"
                value="true"
                name="forms_search_items[include_info]"
              />{{ includeDescriptionLabel }}</label
            >
          </div>
        </div>
        <div v-if="!isPopup" class="from-group">
          <div class="form__control">
            <label
              class="form__checkbox-label form__checkbox-label--additional-condition"
            >
              <input
                id="forms_search_items_include_oripa"
                v-model="includeOripa"
                class="form__checkbox form__checkbox--align-left"
                type="checkbox"
                value="true"
                name="forms_search_items[include_oripa]"
              />{{ includeOriginalPackLabel }}</label
            >
          </div>
        </div>
        <input
          id="forms_search_items_sort"
          type="hidden"
          name="forms_search_items[sort]"
          :value="sort"
        />
        <input
          id="forms_search_items_page"
          value="1"
          type="hidden"
          name="forms_search_items[page]"
        />
        <div class="search-form__btn-area">
          <input
            type="submit"
            name="commit"
            :value="searchButtonLabel"
            class="search-form__search-btn"
            :data-disable-with="searchButtonLabel"
          />
          <button
            name="button"
            type="button"
            class="search-form__reset-btn js-reset-btn"
            @click="clearSearches()"
          >
            {{ resetButtonLabel }}
          </button>
        </div>
      </form>
    </div>
    <h2 v-if="!isPopup && hotTrendKeywords.length" class="heading heading--2">
      {{ enLocale() ? 'Hot keywords' : 'ホットキーワード' }}
    </h2>
    <hot-trend-keyword
      v-if="!isPopup && hotTrendKeywords.length"
      :hot-trend-keywords="hotTrendKeywords"
      :search-by-hot-trend-keyword="searchByHotTrendKeyword"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import iconBusinessAccount from 'images/icon_business_white.svg'
import iconNoImage from 'images/icon-no-image.png'
import FormTemplateRenderer from '~/components/organisms/items/FormTemplateRenderer.vue'
import showGoodsSelector from '~/components/organisms/items/GoodsSelector'
import {
  getSearchFormTemplate,
  getSearchTrendKeywords,
} from '~/api/searchForms'
import HotTrendKeyword from '~/components/organisms/HotTrendKeyword.vue'
import { enLocale } from '~/lib/locale'

interface Data {
  goods: Model.Goods | undefined
  brand: Model.Brand | undefined
  series: Model.Series | undefined
  formTemplate: object | undefined
  keyword: string
  fromPrice: string
  toPrice: string
  includeInfo: boolean
  includeOripa: boolean
  status: string
  quality: string
  selectedSize: { size: string | undefined } | undefined
  hotTrendKeywords: string[]
}

interface Methods {
  showGoodsSelector(): void
  clearSearches(): void
  isSelectedSneaker(): boolean
  priceToRangeSlider(value: string, sliderMode: string): number
  rangeSliderToPrice(value: number, sliderMode: string): number
  setFromPriceFromRangeSlide(value: number): void
  setToPriceFromRangeSlide(value: number): void
  searchByHotTrendKeyword(keyword: string): void
  fetchHotTrendKeywords(
    goods_id: number | undefined,
    brand_id: number | undefined,
    series_id: number | undefined
  ): void
  isMobile(): boolean
  enLocale(): boolean
}

interface Computed {
  iconBusinessAccountUrl: string
  iconNoImageUrl: string
  sliderTrackBackground: string
  sliderMin: number
  sliderMax: number
  disablePriceBar: boolean
}

interface Props {
  keywordLabel: string
  keywordParam: string
  keywordPlaceholder: string
  owner: object
  goodsLabel: string
  selectedGoodsParam: {
    id: number
    name: string
    name_i18n: string
    inspection: string
  }
  brandLabel: string
  selectedBrandParam: { id: number; name: string; name_i18n: string }
  seriesLabel: string
  selectedSeriesParam: { id: number; name: string; name_i18n: string }
  selectedSizeParam: { [name: string]: string } | undefined
  priceLabel: string
  fromPriceParam: string
  toPriceParam: string
  qualityLabel: string
  qualityParam: string
  statusLabel: string
  statusParam: string
  includeDescriptionLabel: string
  includeInfoParam: boolean
  includeOriginalPackLabel: string
  includeOripaParam: boolean
  searchButtonLabel: string
  resetButtonLabel: string
  qualityOptions: { label: string; value: string }[]
  statusOptions: { label: string; value: string }[]
  actionPath: string
  isPopup: boolean
  sort: string
}

const SNEAKER_ID = 4 // item sneaker only
const RANGE_SLIDER_MAX = 32
export default defineComponent<Data, Methods, Computed, Props>({
  components: {
    FormTemplateRenderer,
    HotTrendKeyword,
  },
  props: {
    keywordLabel: {
      type: String,
    },
    keywordParam: {
      type: String,
    },
    keywordPlaceholder: {
      type: String,
    },
    goodsLabel: {
      type: String,
    },
    selectedGoodsParam: {
      type: Object,
      default: null,
    },
    brandLabel: {
      type: String,
    },
    selectedBrandParam: {
      type: Object,
      default: null,
    },
    seriesLabel: {
      type: String,
    },
    selectedSeriesParam: {
      type: Object,
      default: null,
    },
    selectedSizeParam: {
      type: Object,
      default: null,
    },
    priceLabel: {
      type: String,
    },
    fromPriceParam: {
      type: String,
    },
    toPriceParam: {
      type: String,
    },
    qualityLabel: {
      type: String,
    },
    qualityParam: {
      type: String,
    },
    statusLabel: {
      type: String,
    },
    includeDescriptionLabel: {
      type: String,
    },
    includeInfoParam: {
      type: Boolean,
    },
    includeOriginalPackLabel: {
      type: String,
    },
    includeOripaParam: {
      type: Boolean,
    },
    searchButtonLabel: {
      type: String,
    },
    resetButtonLabel: {
      type: String,
    },
    qualityOptions: {
      type: Array,
    },
    statusOptions: {
      type: Array,
    },
    statusParam: {
      type: String,
    },
    actionPath: {
      type: String,
    },
    owner: {
      type: Object,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: String,
    },
  },
  data() {
    return {
      goods: undefined,
      brand: undefined,
      series: undefined,
      formTemplate: undefined,
      keyword: '',
      fromPrice: '',
      toPrice: '',
      includeInfo: false,
      includeOripa: false,
      status: '',
      quality: '',
      selectedSize: undefined,
      hotTrendKeywords: [],
    }
  },
  computed: {
    iconBusinessAccountUrl(): string {
      return iconBusinessAccount
    },
    iconNoImageUrl(): string {
      return iconNoImage
    },
    sliderMin: {
      get(): number {
        return this.priceToRangeSlider(this.fromPrice, 'min')
      },
      set(value: number): void {
        let toPriceNumber = parseInt(this.toPrice, 10)
        toPriceNumber = Number.isNaN(toPriceNumber) ? 500000 : toPriceNumber
        const fromPriceNumber = this.rangeSliderToPrice(value, 'min')

        if (fromPriceNumber > toPriceNumber) {
          this.setToPriceFromRangeSlide(fromPriceNumber)
        }

        this.setFromPriceFromRangeSlide(fromPriceNumber)
      },
    },
    sliderMax: {
      get(): number {
        return this.priceToRangeSlider(this.toPrice, 'max')
      },
      set(value: number): void {
        let fromPriceNumber = parseInt(this.fromPrice, 10)
        fromPriceNumber = Number.isNaN(fromPriceNumber) ? 0 : fromPriceNumber

        const toPriceNumber = this.rangeSliderToPrice(value, 'max')
        if (toPriceNumber < fromPriceNumber) {
          this.setFromPriceFromRangeSlide(toPriceNumber)
        }

        this.setToPriceFromRangeSlide(toPriceNumber)
      },
    },
    sliderTrackBackground(): string {
      if (this.disablePriceBar) return '#c7c7c7'
      const percent1 = (this.sliderMin / RANGE_SLIDER_MAX) * 100
      const percent2 = (this.sliderMax / RANGE_SLIDER_MAX) * 100
      return `linear-gradient(to right, #c7c7c7 ${percent1}% , #444444 ${percent1}% , #444444 ${percent2}%, #c7c7c7 ${percent2}%)`
    },
    disablePriceBar(): boolean {
      return (
        (this.fromPrice === '' || this.fromPrice === null) &&
        (this.toPrice === '' || this.toPrice === null)
      )
    },
  },
  async created() {
    const { data } = await getSearchFormTemplate()
    this.formTemplate = data.form_template

    this.keyword = this.keywordParam
    this.fromPrice = this.fromPriceParam
    this.toPrice = this.toPriceParam
    this.includeInfo = this.includeInfoParam
    this.includeOripa = this.includeOripaParam
    this.status = this.statusParam || ''
    this.quality = this.qualityParam || ''
    this.selectedSize = { size: this.selectedSizeParam?.size }
    if (this.selectedGoodsParam) {
      const inheritGoods: Model.Goods = {
        id: this.selectedGoodsParam.id,
        name: '',
        name_i18n: this.selectedGoodsParam.name_i18n,
        type: '',
        icon_path: '',
        children: [],
        inspection: this.selectedGoodsParam.inspection,
      }
      this.goods = inheritGoods
    }
    if (this.selectedBrandParam) {
      const inheritBrand: Model.Brand = {
        id: this.selectedBrandParam.id,
        name: '',
        name_i18n: this.selectedBrandParam.name_i18n,
      }
      this.brand = inheritBrand
    }
    if (this.selectedSeriesParam) {
      const inheritSeries: Model.Series = {
        id: this.selectedSeriesParam.id,
        name: '',
        name_i18n: this.selectedSeriesParam.name_i18n,
      }
      this.series = inheritSeries
    }
    this.fetchHotTrendKeywords(this.goods?.id, this.brand?.id, this.series?.id)
  },
  methods: {
    clearSearches() {
      this.keyword = ''
      this.fromPrice = ''
      this.toPrice = ''
      this.includeInfo = false
      this.includeOripa = false
      this.status = ''
      this.quality = ''
      this.goods = undefined
      this.series = undefined
      this.brand = undefined
      if (this.selectedSize) {
        this.selectedSize.size = ''
      }
      this.fetchHotTrendKeywords(undefined, undefined, undefined)
    },
    showGoodsSelector() {
      showGoodsSelector((_s) => {
        this.goods = _s.goods
        this.brand = _s.brand
        this.series = _s.series
        this.fetchHotTrendKeywords(
          this.goods?.id,
          this.brand?.id,
          this.series?.id
        )
      })
    },
    isSelectedSneaker() {
      return this.goods?.id === SNEAKER_ID
    },
    priceToRangeSlider(value, sliderMode) {
      const valueNumber: number = parseInt(value, 10)
      if (
        sliderMode === 'min' &&
        (Number.isNaN(valueNumber) || valueNumber <= 0)
      )
        return 0
      if (
        sliderMode === 'max' &&
        (Number.isNaN(valueNumber) || valueNumber >= 500000)
      )
        return RANGE_SLIDER_MAX

      switch (true) {
        case valueNumber <= 0:
          return 0
        case valueNumber <= 5000:
          return valueNumber / 500
        case valueNumber <= 10000:
          return 10 + (valueNumber - 5000) / 1000
        case valueNumber <= 50000:
          return 15 + (valueNumber - 10000) / 5000
        case valueNumber <= 100000:
          return 23 + (valueNumber - 50000) / 10000
        case valueNumber <= 500000:
          return 28 + (valueNumber - 100000) / 100000
        default:
          return 0
      }
    },
    rangeSliderToPrice(value, sliderMode) {
      if (sliderMode === 'min' && value === 0) return 0
      if (sliderMode === 'max' && value === RANGE_SLIDER_MAX) return 500000

      switch (true) {
        case value <= 0:
          return 0
        case value <= 10:
          return value * 500
        case value <= 15:
          return 5000 + (value - 10) * 1000
        case value <= 23:
          return 10000 + (value - 15) * 5000
        case value <= 28:
          return 50000 + (value - 23) * 10000
        case value <= RANGE_SLIDER_MAX:
          return 100000 + (value - 28) * 100000
        default:
          return 0
      }
    },
    setFromPriceFromRangeSlide(value) {
      this.fromPrice = value <= 0 ? '' : value.toString()
    },
    setToPriceFromRangeSlide(value) {
      this.toPrice = value >= 500000 ? '' : value.toString()
    },
    searchByHotTrendKeyword(keyword) {
      this.keyword = keyword

      this.$nextTick(() => {
        if (
          !this.$refs.searchForm ||
          !(this.$refs.searchForm instanceof HTMLFormElement)
        )
          return
        this.$refs.searchForm.submit()
      })
    },
    async fetchHotTrendKeywords(goods_id, brand_id, series_id) {
      const { data } = await getSearchTrendKeywords(
        goods_id,
        brand_id,
        series_id
      )
      const keywords = data.search_trends.map(
        (search_trend) => search_trend.keyword
      )

      this.hotTrendKeywords = this.isMobile() ? keywords.slice(0, 10) : keywords
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    enLocale() {
      return enLocale()
    },
  },
})
</script>

<style scoped lang="scss">
.search-form {
  border-radius: $round;
  display: block;
  background: $co-white;
  box-shadow: $shadow-box;
  width: 200px;
  padding: 0 $space-M $space-L;
  // FIXME: can not find mixin => can not build
  @media screen and (max-width: 768px) {
    padding: 0 $space-L $space-LL;
    max-width: 100%;
    width: auto;
  }

  &__label {
    margin: 0 $inner-space-XS;
    text-align: left;
  }

  &__label:before {
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: $co-main-dark;
  }

  &__label:after {
    top: 3px;
    left: 3px;
    box-sizing: border-box;
    width: 0px;
    height: 0px;
    border: 3px solid transparent;
    border-top: 5px solid $co-white;
  }

  &__input-price {
    display: flex;
    display: -webkit-flex;
    min-width: 170px;
    line-height: 2em;
    align-items: center;

    & .form__input {
      padding-left: $space-M;
    }
  }

  &__partition {
    margin: 0 $inner-space-XS;
    color: $co-gray;
  }

  &__currency-symbol {
    color: $co-gray;
    font-size: $font-size-LL;
    font-weight: 700;
  }

  &__double-slider {
    position: relative;
    width: 100%;
    height: 20px;
  }

  &__double-slider-track {
    background: $co-border;
    width: 100%;
    height: 5px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    border-radius: 5px;
  }

  &__double-slider-input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    position: absolute;
    margin: auto;
    top: 6px;
    bottom: 0;
    background-color: transparent;
    pointer-events: none;
  }

  &__double-slider-input[type='range']::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 5px;
  }

  &__double-slider-input[type='range']::-moz-range-track {
    -moz-appearance: none;
    height: 5px;
  }

  &__double-slider-input[type='range']::-ms-track {
    appearance: none;
    height: 5px;
  }

  &__double-slider-input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    background-color: $co-dark-gray;
    cursor: pointer;
    margin-top: -9px;
    pointer-events: auto;
    border-radius: 50%;
  }

  &__double-slider-input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $co-dark-gray;
    pointer-events: auto;
    border: none;
  }

  &__double-slider-input[type='range']::-ms-thumb {
    appearance: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $co-dark-gray;
    pointer-events: auto;
  }

  &__double-slider-input[type='range']:active::-webkit-slider-thumb {
    background-color: $co-dark-gray;
    border: 1px solid $co-dark-gray;
  }

  &__double-slider-input[type='range'][data-disable='true']::-webkit-slider-thumb {
    background-color: $co-gray;
  }

  &__double-slider-input[type='range'][data-disable='true']::-moz-range-thumb {
    background-color: $co-gray;
  }

  &__double-slider-input[type='range'][data-disable='true']::-ms-thumb {
    background-color: $co-gray;
  }

  &__btn-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__search-btn {
    margin: $block-space-M auto;
    width: 100%;
    height: 48px;
    font-size: $font-size-M;
    font-weight: 700;
    text-align: center;
    background-color: $co-red;
    color: $co-white;
    border-radius: $space-XS;
    &:hover {
      filter: alpha(opacity=60);
      -moz-opacity: 0.6;
      opacity: 0.6;
    }
  }

  &__reset-btn {
    margin-bottom: 0;
    width: 100%;
    height: 48px;
    font-size: $font-size-M;
    font-weight: 700;
    text-align: center;
    background-color: $co-main-dark;
    color: $co-white;
    border-radius: $space-XS;

    &:hover {
      filter: alpha(opacity=60);
      -moz-opacity: 0.6;
      opacity: 0.6;
    }
  }

  &__series-selector-text {
    color: $co-black;
    display: block;
    text-align: left;
    padding: 0 4px;
    width: 100%;
    min-height: 40px;
    border: 1px solid $co-border;
    background: $co-white;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    position: relative;
    padding-left: $space-M;

    &--blank {
      color: $co-gray;
    }

    &:before {
      content: '';
      position: absolute;
      top: 18px;
      right: $space-M;
      display: block;
      width: 0;
      height: 0;
      margin: -2px 0 0 0;
      border: 5px solid transparent;
      border-top: 7px solid $co-border;
    }
  }

  &__form-control {
    display: block;
    margin-bottom: $space-M;
  }
  & .form {
    &__group {
      &--first {
        padding-top: $space-L;
        // FIXME: can not find mixin => can not build
        @media screen and (max-width: 768px) {
          padding-top: $space-LL;
        }
      }
    }

    &__input {
      color: $co-black;
      padding-left: $space-M;
      &--select-wrapper::before {
        right: $space-M;
      }

      &--select {
        color: $co-gray;
      }

      &--select:has(option:checked:not([value=''])) {
        color: $co-black;
      }
    }

    &__checkbox-label {
      font-size: $space-M;
    }
  }
}
</style>
