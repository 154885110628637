import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "just-purchased-modal" }
const _hoisted_2 = { class: "just-purchased-modal__product-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "my-m text-center just-purchased-modal__sub-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "just-purchased-modal__product-image",
        src: _ctx.productImageSrc
      }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("img", {
      class: "mt-m",
      src: _ctx.jutPurchasedImageUrl
    }, null, 8 /* PROPS */, _hoisted_4),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.subText), 1 /* TEXT */),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mt-s text-bold just-purchased-modal__sub-title" }, " 商品をシェアしよう！ ", -1 /* HOISTED */)),
    _createElementVNode("button", {
      class: "mx-0 my-m btn btn-x just-purchased-modal__x-share-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareX()))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "btn__left-icon jirafficon jirafficon-x just-purchased-modal__x-share-icon" }, null, -1 /* HOISTED */),
      _createElementVNode("span", null, "Xでシェア", -1 /* HOISTED */)
    ])),
    _createElementVNode("button", {
      class: "my-m btn btn--gray just-purchased-modal__close-button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClickCloseButton()))
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("span", { class: "just-purchased-modal__button-sub-text" }, " 取引画面を見る ", -1 /* HOISTED */)
    ]))
  ]))
}