import { useModel as _useModel, defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'TextArea',
  props: {
    "modelValue": {},
    "modelModifiers": {},
  },
  emits: ["update:modelValue"],
  setup(__props) {

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const value = _useModel(__props, "modelValue")

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("textarea", {
    class: "form__input form__input--textarea",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
  }, null, 512 /* NEED_PATCH */)), [
    [_vModelText, value.value]
  ])
}
}

})