import { createApp, h } from 'vue'
import { Controller } from 'stimulus'
import AutocompletableSelectBox from '~/components/molecules/form/AutocompletableSelectBox.vue'

export default class VueSelectController extends Controller {
  initialize(): void {
    const props = JSON.parse(this.data.get('props') || '{}')
    const app = createApp({
      render: () => h(AutocompletableSelectBox, props),
    })
    const component = app.mount(document.createElement('div'))

    this.element.appendChild(component.$el)
  }
}
