import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_label = _resolveComponent("form-label")!
  const _component_searchable_select_box = _resolveComponent("searchable-select-box")!
  const _component_form_input = _resolveComponent("form-input")!
  const _component_form_control = _resolveComponent("form-control")!
  const _component_form_group = _resolveComponent("form-group")!

  return (_openBlock(), _createBlock(_component_form_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_form_label, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$props.label), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_form_control, null, {
        default: _withCtx(() => [
          _createVNode(_component_searchable_select_box, {
            name: "searchable-select-box",
            selected: _ctx.selected,
            options: _ctx.filterOptions,
            multiple: _ctx.$props.multiple,
            "option-label": "name",
            onInput: _ctx.handleChangeStatuses,
            onSearch: _ctx.onSearchOption
          }, null, 8 /* PROPS */, ["selected", "options", "multiple", "onInput", "onSearch"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selected, (_selected) => {
            return (_openBlock(), _createBlock(_component_form_input, {
              id: "select-box-input-hidden",
              key: _selected.id,
              type: "hidden",
              modelValue: _selected.id,
              name: _ctx.$props.name
            }, null, 8 /* PROPS */, ["modelValue", "name"]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}