import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "submit",
    class: "form__submit-btn",
    value: _ctx.value,
    disabled: _ctx.disabled
  }, null, 8 /* PROPS */, _hoisted_1))
}