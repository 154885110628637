import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form__checkbox-label" }
const _hoisted_2 = ["name", "value", "checked"]


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckBox',
  props: /*@__PURE__*/_mergeModels({
    name: {},
    value: {},
    checked: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const checkValue = _useModel(__props, "modelValue")



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkValue).value = $event)),
      name: _ctx.name,
      class: "form__checkbox",
      type: "checkbox",
      value: _ctx.value,
      checked: _ctx.checked
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelCheckbox, checkValue.value]
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})