import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('form__input--select-wrapper ' + _ctx.selectorWrapperClass)
  }, [
    _createVNode(_component_v_select, {
      id: _ctx.selectorId,
      modelValue: _ctx.input.selected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.selected) = $event)),
      multiple: _ctx.multiple,
      taggable: _ctx.taggable,
      class: _normalizeClass('form__input ' + _ctx.additionalClassName() + '' + _ctx.selectorClass),
      options: _ctx.options,
      reduce: (option) => option.value,
      onInput: _ctx.updateValue,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event))),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event)))
    }, null, 8 /* PROPS */, ["id", "modelValue", "multiple", "taggable", "class", "options", "reduce", "onInput"]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.input.selected) = $event)),
      type: "hidden",
      name: _ctx.name
    }, null, 8 /* PROPS */, _hoisted_1), [
      [_vModelText, _ctx.input.selected]
    ])
  ], 2 /* CLASS */))
}