<template>
  <div
    v-if="messages.length > 0"
    class="form__field-error"
    role="alert"
    aria-label="Error Message"
  >
    <div v-for="message in messages" :key="message">
      {{ message }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    messages: {
      type: Array,
      default: (): string[] => [],
    },
  },
})
</script>
