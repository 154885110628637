import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'
import LoadingModalController from '~/controllers/loading_modal_controller'
import TreeSelector from '~/components/molecules/TreeSelector.vue'
import { getGoods } from '~/api/goods'
import { getBrands } from '~/api/brands'
import { getSeries } from '~/api/series'
import { enLocale } from '~/lib/locale'

export interface Result {
  goodsSeries?: Model.Goods
  goods?: Model.Goods
  brand?: Model.Brand
  series?: Model.Series
}

interface SelectableCell<Value = unknown> {
  label: string
  isLeaf: boolean
  value: Value
}

interface HeadingCell {
  heading: true
  label: string
}

interface Sheet<Value = unknown> {
  title: string
  list: Array<SelectableCell<Value> | HeadingCell>
}

const showGoodsSelector = async (callback: (result: Result) => void) => {
  const modal = findController<ModalController>('modal')
  if (!modal) return
  // eslint-disable-next-line
  const loader:any = findController<LoadingModalController>('loading-modal')

  loader.show()
  const { data } = await getGoods()
  loader.hide()

  const { goods } = data
  let currentSelectModel = 'goods'

  const makeGoodsSheets = (gs: Model.Goods[]) => ({
    title: enLocale() ? 'Please select a goods' : '商材を選んでください',
    list: gs.map((g) => ({
      label: g.name_i18n,
      isLeaf: false,
      value: g,
    })),
  })

  const makeBrandSheets = (brands: Api.Brands.Index.Response) => ({
    title: enLocale() ? 'Please select a brand' : 'ブランドを選んでください',
    list: [
      ...brands.brands.all.map((b) => ({
        label: b.name_i18n,
        isLeaf: false,
        value: b,
      })),
    ],
  })

  const makeSeriesSheets = (series: Model.Series[]) => ({
    title: enLocale() ? 'Please select a series' : 'カテゴリを選んでください',
    list: [
      ...series.map((c) => ({
        label: c.name_i18n,
        isLeaf: true,
        value: c,
      })),
    ],
  })

  const result: Result = {
    brand: undefined,
    series: undefined,
    goods: undefined,
  }

  const isGoods = (value: unknown): value is Model.Goods => true
  const isBrand = (value: unknown): value is Model.Brand => true
  const isSeries = (value: unknown): value is Model.Series => true

  // eslint-disable-next-line
  const onSelected = async (selected: SelectableCell, push: (sheet: Sheet) => void) => {
    if (currentSelectModel === 'goods') {
      if (!isGoods(selected.value)) throw new Error('Goodsではないvalueです')
      if (selected.value.children) {
        push(makeGoodsSheets(selected.value.children))
        return
      }
      currentSelectModel = 'brand'

      loader.show()
      const brands = await getBrands(selected.value.id)
      loader.hide()
      push(makeBrandSheets(brands.data))
      result.goods = selected.value
    } else if (currentSelectModel === 'brand') {
      currentSelectModel = 'series'
      if (typeof result.goods === 'undefined') {
        throw new Error('Goodsがundefindです')
      }

      if (isBrand(selected.value)) {
        result.brand = selected.value
      }
      if (typeof result.brand === 'undefined') {
        throw new Error('Brandがundefindです')
      }
      loader.show()
      const series = await getSeries(result.goods.id, result.brand.id)
      loader.hide()

      if (series.data.series.length <= 1) {
        callback(result)
        modal.close()
        return
      }
      push(makeSeriesSheets(series.data.series))
    } else if (currentSelectModel === 'series') {
      if (isSeries(selected.value)) {
        result.series = selected.value
      }
      callback(result)
      modal.close()
    }
  }

  const onBack = () => {
    if (currentSelectModel === 'brand') {
      currentSelectModel = 'goods'
    } else if (currentSelectModel === 'series') {
      currentSelectModel = 'brand'
    }
  }

  const treeSelector = modal.showWithVue(TreeSelector, {
    onSelected,
    onBack,
  })
  // FIXME
  // eslint-disable-next-line
  // @ts-ignore
  treeSelector.pushSheet(makeGoodsSheets(goods))
}

export default showGoodsSelector
