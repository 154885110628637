import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "item-estimates" }
const _hoisted_2 = { class: "item-estimates__row item-estimates__row-header" }
const _hoisted_3 = { class: "item-estimates__subject" }
const _hoisted_4 = {
  class: "item-estimates__dd",
  "aria-labelledby": "売上金"
}
const _hoisted_5 = { class: "item-estimates__row" }
const _hoisted_6 = { class: "item-estimates__details" }
const _hoisted_7 = { class: "item-estimates__dd" }
const _hoisted_8 = { class: "item-estimates__details" }
const _hoisted_9 = { class: "item-estimates__dd" }
const _hoisted_10 = { class: "item-estimates__row item-estimates__row-header" }
const _hoisted_11 = { class: "item-estimates__subject item-estimates__subject-description" }
const _hoisted_12 = {
  key: 0,
  class: "item-estimates__description"
}
const _hoisted_13 = { class: "item-estimates__amount" }
const _hoisted_14 = { class: "item-estimates__row" }
const _hoisted_15 = {
  key: 0,
  class: "item-estimates__row item-estimates__row-header"
}
const _hoisted_16 = {
  key: 1,
  class: "item-estimates__row"
}
const _hoisted_17 = { class: "item-estimates__details" }
const _hoisted_18 = { class: "item-estimates__dd" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", null, "売上金", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.incomePrice()), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("div", null, "商品価格", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.sales()), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "販売手数料", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.chargeText()), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[3] || (_cache[3] = _createElementVNode("div", null, "販売価格（購入者負担金額）", -1 /* HOISTED */)),
        (_ctx.sellingPriceDescription)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.sellingPriceDescription()), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.sellingPrice()), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sellingPriceDetails(), (detail) => {
        return (_openBlock(), _createElementBlock("div", { key: detail }, [
          (_openBlock(), _createElementBlock("div", {
            key: detail.label,
            class: "item-estimates__details"
          }, [
            (_openBlock(), _createElementBlock("div", {
              key: detail.label
            }, _toDisplayString(detail.label), 1 /* TEXT */)),
            (_openBlock(), _createElementBlock("div", {
              key: detail.label + detail.value,
              class: "item-estimates__dd"
            }, _toDisplayString(detail.value), 1 /* TEXT */))
          ]))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    (_ctx.earningPointsDisplayable())
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "item-estimates__subject" }, [
            _createElementVNode("div", null, "獲得ポイント")
          ], -1 /* HOISTED */)
        ])))
      : _createCommentVNode("v-if", true),
    (_ctx.earningPointsDisplayable())
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.earningPointsText()), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}