import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "type", "name"]

import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars

export default /*@__PURE__*/_defineComponent({
  __name: 'FormInput',
  props: /*@__PURE__*/_mergeModels({
    placeholder: {},
    type: {},
    class: {},
    name: {}
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const value = _useModel(__props, "modelValue")
const error = ref(false)

const props = __props

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  let name = 'form__input'
  if (props.class !== null) {
    name += ` ${props.class}`
  }
  if (error.value) {
    name += ' form__input--field-error'
  }
  return name
})

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: _normalizeClass(className.value),
    placeholder: _ctx.placeholder,
    type: _ctx.type,
    name: _ctx.name,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
  }, null, 10 /* CLASS, PROPS */, _hoisted_1)), [
    [_vModelDynamic, value.value]
  ])
}
}

})