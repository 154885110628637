<template>
  <label class="form__label">
    <slot />
    <span v-if="required" class="form__required"> ※必須 </span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
