import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lottery-result-prize-cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottery_result_prize_card = _resolveComponent("lottery-result-prize-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lotteryResultPrizes, (lotteryResultPrize) => {
      return (_openBlock(), _createBlock(_component_lottery_result_prize_card, {
        key: lotteryResultPrize.id,
        "lottery-result-prize": lotteryResultPrize,
        "is-mypage": _ctx.is_mypage,
        onOnOpenConvertCouponModal: _ctx.onOpenConvertCouponModal,
        onOnTransitRequestShippingPage: _ctx.onTransitRequestShippingPage
      }, null, 8 /* PROPS */, ["lottery-result-prize", "is-mypage", "onOnOpenConvertCouponModal", "onOnTransitRequestShippingPage"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}