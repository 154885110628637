<template>
  <div :class="wrapperClassName">
    <select :id="selectorId" :name="name" :class="className" v-model="value">
      <slot />
    </select>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const value = defineModel({ type: String })
const error = ref(false)

const props = defineProps<{
  selectorWrapperClass?: string
  selectorClass?: string
  selectorId?: string
  name: string
}>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  const names = ['form__input']
  if (props.selectorClass !== undefined) {
    names.push(props.selectorClass)
  }
  if (error.value) {
    names.push('form__input--field-error')
  }
  return names.join(' ')
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const wrapperClassName = computed((): string => {
  const names = ['form__input--select-wrapper']
  if (props.selectorWrapperClass !== undefined) {
    names.push(props.selectorWrapperClass)
  }
  return names.join(' ')
})
</script>

<style scope lang="scss">
.has-value {
  color: $co-black;
}

.blank-value {
  color: $co-gray;
}
</style>
