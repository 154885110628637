<template>
  <div v-if="body" class="snackbar">
    <ul>
      <transition-group name="snack" tag="span">
        <li
          :key="title"
          :class="'snack snack--' + variant"
          aria-label="Snack Bar"
        >
          <i
            v-if="icon"
            :class="`icon jirafficon jirafficon-${icon}`"
            :aria-label="icon"
          />
          {{ body }}
          <i
            class="close-icon jirafficon jirafficon-close"
            aria-label="閉じる"
            @click="body = undefined"
          />
        </li>
      </transition-group>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    title: String,
    icon: { type: String },
    variant: { type: String, default: 'default' },
  },
  setup(props) {
    const body = ref(props.title)
    return { body }
  },
})
</script>

<style scoped lang="scss">
.snackbar {
  position: relative;
  z-index: $z-index-snackbar;
  .snack {
    position: fixed;
    bottom: $space-M;
    right: $space-M;
    padding: $space-M;
    transform: translateY(0px);
    transition: all 0.2s ease-in;
    margin: 0 auto;
    background: $co-white;
    border-radius: $round;
    min-width: 300px;
    box-shadow: $shadow-box;
    font-weight: bold;
    display: flex;
    align-items: center;

    &--notice {
      background: $co-yellow;
      color: $co-white;
    }

    &--success {
      background: $co-green;
      color: $co-white;
    }

    &--warning {
      background: $co-red;
      color: $co-white;
    }
  }
  .close-icon {
    margin-left: auto;
    font-size: $font-size-XL;
    cursor: pointer;
  }
  .icon {
    margin-right: $space-S;
    font-size: $font-size-2XL;
  }
  .snack-enter-active,
  .snack-leave-active {
    transition: all 0.2s ease-in;
  }
  .snack-enter,
  .snack-leave-to {
    transform: translateY(100px);
  }
}
</style>
