<template>
  <div class="convert-coupon-modal">
    <div v-if="!existsCoupon" class="convert-coupon-modal__top-area">
      <div class="convert-coupon-modal__thumbnail-wrapper">
        <img
          class="convert-coupon-modal__thumbnail"
          :src="lotteryResultPrize.thumbnail_url"
        />
      </div>
      <div class="convert-coupon-modal__descriptions">
        <p class="convert-coupon-modal__prize-title">
          {{ lotteryResultPrize.prize_title }}
        </p>
        <p class="convert-coupon-modal__convert-coupon-question">
          を下記に変換しますか？
        </p>
      </div>
    </div>
    <p v-else class="convert-coupon-modal__converted-coupon-success">
      変換しました！
    </p>
    <div class="convert-coupon-modal__bottom-area">
      <div
        v-if="!existsCoupon"
        class="convert-coupon-modal__coupon-area-wrapper"
      >
        <div class="convert-coupon-modal__coupon-area">
          <div class="convert-coupon-modal__coupon-name-area">
            <div class="convert-coupon-modal__coupon-image-wrapper">
              <img
                class="convert-coupon-modal__coupon-image"
                :src="iconImage"
              />
            </div>
            <p class="convert-coupon-modal__coupon-name">
              {{
                couponName(lotteryResultPrize.convertable_coupon_discount_name)
              }}
            </p>
          </div>
          <p
            v-if="lotteryResultPrize.convertable_coupon_condition_price"
            class="convert-coupon-modal__coupon-condition-price"
          >
            ({{ couponConditionPrice }})
          </p>
          <p class="convert-coupon-modal__coupon-expired-at">
            {{ couponExpiredAt }}
          </p>
        </div>
        <div
          v-if="existsConvertablePoints"
          class="convert-coupon-modal__coupon-area"
        >
          <div class="convert-coupon-modal__coupon-name-area">
            <div class="convert-coupon-modal__points-icon-wrapper">
              <i class="fs-xl mr-s jirafficon jirafficon-point" />
            </div>
            <p class="convert-coupon-modal__coupon-name">
              {{ format(lotteryResultPrize.convertable_points) }}
              ポイント
            </p>
          </div>
          <p class="convert-coupon-modal__coupon-expired-at">
            {{ pointsExpiredAt }}
          </p>
        </div>
        <p class="convert-coupon-modal__coupon-convert-attention">
          ※一度交換すると元に戻せません。
        </p>
        <p
          v-if="isRare"
          class="convert-coupon-modal__coupon-convert-rare-attention"
        >
          貴重なカードですが本当に交換しても良いですか？
        </p>
      </div>
      <div
        v-else
        class="convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted"
      >
        <div class="convert-coupon-modal__coupon-name-area">
          <div class="convert-coupon-modal__converted-icon-wrapper">
            <i class="fs-3xl mr-xs jirafficon jirafficon-check-circle-filled" />
          </div>
          <p class="convert-coupon-modal__coupon-name">
            {{ couponName(coupon.name) }}
          </p>
        </div>
        <p class="convert-coupon-modal__coupon-condition-price">
          ({{ convertedCouponConditionPrice }})
        </p>
        <p class="convert-coupon-modal__coupon-expired-at">
          {{ convertedCouponExpiredAt }}
        </p>
      </div>
      <div
        v-if="existsPoints"
        class="convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted"
      >
        <div class="convert-coupon-modal__coupon-name-area">
          <div class="convert-coupon-modal__converted-icon-wrapper">
            <i class="fs-3xl mr-xs jirafficon jirafficon-check-circle-filled" />
          </div>
          <p class="convert-coupon-modal__coupon-name">
            {{ format(point_detail.points) }}
            ポイント
          </p>
        </div>
        <p class="convert-coupon-modal__converted-coupon-description">
          所有ポイント:
          {{ format(personal.points) }}
          ポイント
        </p>
      </div>
    </div>
    <div v-if="!existsCoupon" class="convert-coupon-modal__button-area">
      <button
        class="convert-coupon-modal__cancel-button btn btn--small btn--ghost-primary"
        @click="onClickCancelButton"
      >
        やめておく
      </button>
      <button
        :class="[
          'convert-coupon-modal__convert-coupon-button btn btn--small',
          isConvertedCoupon ? 'btn--disabled' : 'btn--primary-light',
        ]"
        :disabled="isConvertedCoupon"
        @click="onClickConvertButton"
      >
        変換する
      </button>
    </div>
    <div v-else class="convert-coupon-modal__button-area">
      <button
        class="convert-coupon-modal__close-button btn"
        @click="onClickCancelButton"
      >
        閉じる
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import couponImage from 'images/oripa/icon_coupon.png'
import selectImage from 'images/oripa/icon_select.png'
import numberFormattable from '~/components/mixins/numberFormattable'

interface Data {
  isConvertedCoupon: boolean
}

interface Methods {
  couponName(name: string): string
  onClickConvertButton(): void
}

interface Computed {
  iconImage: string
  couponConditionPrice: string
  couponExpiredAt: string
  existsCoupon: boolean
  existsConvertablePoints: boolean
  convertedCouponExpiredAt: string
  convertedCouponConditionPrice: string
  pointsExpiredAt: string
  existsPoints: boolean
  isRare: boolean
}

interface Props {
  lotteryResultPrize: Model.LotteryResultPrize
  coupon: Model.UserPossessedCoupon
  point_detail: Model.PointDetail
  personal: Model.Personal
  onClickCancelButton(): void
  onClickConvertCouponButton(lotteryResultPrize: Model.LotteryResultPrize): void
}

export default defineComponent<Data, Methods, Computed, Props>({
  mixins: [numberFormattable],
  props: {
    lotteryResultPrize: {
      type: Object,
      required: true,
    },
    coupon: {
      type: Object,
    },
    point_detail: {
      type: Object,
    },
    personal: {
      type: Object,
    },
    onClickCancelButton: {
      type: Function,
      required: true,
    },
    onClickConvertCouponButton: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isConvertedCoupon: false,
    }
  },
  computed: {
    iconImage(): string {
      if (this.existsCoupon) {
        return selectImage
      }

      return couponImage
    },
    couponConditionPrice(): string {
      if (!this.lotteryResultPrize.convertable_coupon_condition_price) {
        return '対象商品価格: なし'
      }

      return `対象商品価格: ¥${this.lotteryResultPrize.convertable_coupon_condition_price.toLocaleString()}以上`
    },
    couponExpiredAt(): string {
      if (!this.lotteryResultPrize.convertable_coupon_expired_at) {
        return '有効期限: なし'
      }

      return `有効期限: ${this.lotteryResultPrize.convertable_coupon_expired_at}`
    },
    existsCoupon(): boolean {
      if (this.coupon) {
        return true
      }

      return false
    },
    convertedCouponExpiredAt(): string {
      return `有効期限: ${this.coupon?.expired_at || 'なし'}`
    },
    convertedCouponConditionPrice(): string {
      if (!this.coupon?.conditions) {
        return '対象商品価格: なし'
      }

      return this.coupon.conditions
        .map((condition) => {
          return `${condition.label}: ${condition.value}`
        })
        .join(', ')
    },
    existsConvertablePoints(): boolean {
      return !!this.lotteryResultPrize.convertable_points
    },
    pointsExpiredAt(): string {
      if (!this.lotteryResultPrize.convertable_points_expire_at) {
        return '有効期限: なし'
      }

      return `有効期限: ${this.lotteryResultPrize.convertable_points_expire_at}`
    },
    existsPoints(): boolean {
      return !!this.point_detail?.points
    },
    isRare(): boolean {
      return (
        this.lotteryResultPrize.grade === 'first_winning' ||
        this.lotteryResultPrize.grade === 'second_winning'
      )
    },
  },
  methods: {
    couponName(name: string): string {
      if (!name) {
        return ''
      }

      return `${name}offクーポン`
    },
    onClickConvertButton(): void {
      this.isConvertedCoupon = true
      this.onClickConvertCouponButton(this.lotteryResultPrize)
    },
  },
})
</script>

<style scoped lang="scss">
.convert-coupon-modal {
  padding: $space-L;

  &__top-area {
    display: flex;
    margin-bottom: $space-M;
  }

  &__thumbnail-wrapper {
    width: 80px;
    margin-right: $space-S;
  }

  &__thumbnail {
    width: 100%;
  }

  &__prize-title {
    font-weight: bold;
  }

  &__convert-coupon-question {
    margin-top: $space-S;
    font-weight: bold;
  }

  &__converted-coupon-success {
    margin-bottom: $space-M;
    font-size: $font-size-S;
    font-weight: bold;
  }

  &__coupon-area {
    margin-bottom: $space-M;
    padding: $space-M;
    background-color: $co-bg;
  }

  &__coupon-name-area {
    display: flex;
    align-items: center;
    margin-bottom: $space-XS;
  }

  &__coupon-image-wrapper {
    width: 28px;
    margin-right: $space-S;
  }

  &__coupon-image {
    width: 100%;
  }

  &__coupon-name {
    font-size: $font-size-LL;
    font-weight: bold;
    color: $co-black;
  }

  &__coupon-condition-price {
    color: $co-black;
    font-size: $font-size-XS;
    margin-bottom: $space-S;
  }

  &__coupon-expired-at {
    color: $co-gray;
    font-size: $font-size-XS;
  }

  &__converted-coupon-description {
    font-size: $font-size-S;
  }

  &__coupon-convert-attention {
    margin-bottom: $space-S;
    color: $co-red;
    text-align: center;
    font-size: $font-size-S;
  }

  &__coupon-convert-rare-attention {
    background-color: $co-light-red;
    color: $co-dark-red;
    font-size: $font-size-S;
    font-weight: bold;
    margin-bottom: $space-S;
    padding: $space-S;
    text-align: center;
  }

  &__button-area {
    display: flex;
    gap: $space-XS;
  }

  &__cancel-button {
    flex: 1;
  }

  &__convert-coupon-button {
    flex: 1;
  }

  &__close-button {
    width: 100%;
    max-width: 360px;
  }

  &__points-icon-wrapper {
    color: $co-yellow;
    font-size: $font-size-XL;
  }

  &__converted-icon-wrapper {
    color: $co-green;
    font-size: $font-size-XL;
  }
}
</style>
