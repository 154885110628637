<template>
  <label :class="className">
    <input
      :name="name"
      class="form__radio"
      type="radio"
      :value="value"
      v-model="radioValue"
      :disabled="disabled"
    />
    <slot />
  </label>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const radioValue = defineModel()
const error = ref(false)

const props = defineProps<{
  name: string
  value: string
  checked?: boolean
  disabled?: boolean
  class?: string
}>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const className = computed((): string => {
  let name = 'form__radiobox-label'
  if (props.class !== null) {
    name += ` ${props.class}`
  }
  if (error.value) {
    name += ' form__input--field-error'
  }
  return name
})

defineEmits(['update:radioValue'])
</script>
