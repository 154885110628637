import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "lottery-result-prize-card" }
const _hoisted_2 = {
  key: 0,
  class: "lottery-result-prize-card__coupon"
}
const _hoisted_3 = { class: "lottery-result-prize-card__thumbnail-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "lottery-result-prize-card__info" }
const _hoisted_6 = { class: "lottery-result-prize-card__info-text-area" }
const _hoisted_7 = { class: "lottery-result-prize-card__prize-title" }
const _hoisted_8 = { class: "lottery-result-prize-card__target-category" }
const _hoisted_9 = { class: "lottery-result-prize-card__expired-at" }
const _hoisted_10 = { class: "lottery-result-prize-card__info-wrapper" }
const _hoisted_11 = { class: "lottery-result-prize-card__grade-wrapper" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "lottery-result-prize-card__thumbnail-wrapper" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "lottery-result-prize-card__info" }
const _hoisted_16 = { class: "lottery-result-prize-card__info-text-area lottery-result-prize-card__info-text-area--card" }
const _hoisted_17 = { class: "lottery-result-prize-card__lottery-title" }
const _hoisted_18 = { class: "lottery-result-prize-card__prize-title" }
const _hoisted_19 = {
  key: 0,
  class: "lottery-result-prize-card__date-area"
}
const _hoisted_20 = { class: "lottery-result-prize-card__expired-at" }
const _hoisted_21 = {
  key: 0,
  class: "lottery-result-prize-card__date-in-words"
}
const _hoisted_22 = {
  key: 0,
  class: "lottery-result-prize-card__button-area"
}
const _hoisted_23 = {
  key: 0,
  class: "lottery-result-prize-card__transit-request-shipping-btn-wrapper"
}
const _hoisted_24 = ["disabled"]
const _hoisted_25 = {
  key: 1,
  class: "lottery-result-prize-card__open-convert-coupon-btn-wrapper"
}
const _hoisted_26 = ["disabled"]
const _hoisted_27 = {
  key: 0,
  class: "lottery-result-prize-card__open-convert-points-label"
}
const _hoisted_28 = { class: "lottery-result-prize-card__open-convert-payouts" }
const _hoisted_29 = { class: "lottery-result-prize-card__open-convert-payout-coupon" }
const _hoisted_30 = {
  key: 0,
  class: "lottery-result-prize-card__open-convert-payout-points"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isMypage && !_ctx.isOripaCard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "lottery-result-prize-card__thumbnail",
              src: _ctx.couponImage
            }, null, 8 /* PROPS */, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.couponName), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.targetCategory), 1 /* TEXT */),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.couponExpiredAt), 1 /* TEXT */)
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['lottery-result-prize-card__oripa-card', _ctx.prizeCardInnerClass])
        }, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("img", {
                class: "lottery-result-prize-card__grade",
                src: _ctx.gradeImage
              }, null, 8 /* PROPS */, _hoisted_12)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("img", {
                class: "lottery-result-prize-card__thumbnail",
                src: _ctx.lotteryResultPrize.thumbnail_url
              }, null, 8 /* PROPS */, _hoisted_14)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.lotteryResultPrize.lottery_title), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.lotteryResultPrize.prize_title), 1 /* TEXT */),
                (_ctx.isConvertable && _ctx.isShippable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.statusText), 1 /* TEXT */),
                      (_ctx.isStored)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_21, _toDisplayString(_ctx.shippingRequestDueDateInWords), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true)
              ])
            ])
          ]),
          (_ctx.isStored)
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                (_ctx.isShippable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createElementVNode("button", {
                        class: _normalizeClass([
              'lottery-result-prize-card__transit-request-shipping-btn btn btn--small btn--full',
              _ctx.transitRequestShippingBtnClass,
            ]),
                        disabled: !_ctx.isStored,
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTransitRequestShippingPage && _ctx.onTransitRequestShippingPage(...args)))
                      }, _cache[2] || (_cache[2] = [
                        _createElementVNode("i", { class: "fs-m mr-xs jirafficon jirafficon-track" }, null, -1 /* HOISTED */),
                        _createTextVNode(" 発送申請 ")
                      ]), 10 /* CLASS, PROPS */, _hoisted_24)
                    ]))
                  : _createCommentVNode("v-if", true),
                (_ctx.isConvertable)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _createElementVNode("button", {
                        class: _normalizeClass([
              'lottery-result-prize-card__open-convert-coupon-btn btn btn--small btn--full',
              _ctx.openConvertCouponBtnClass,
            ]),
                        disabled: !_ctx.isStored,
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onOpenConvertCouponModal && _ctx.onOpenConvertCouponModal(...args)))
                      }, [
                        _cache[4] || (_cache[4] = _createTextVNode(" クーポン ")),
                        (_ctx.existsConvertablePoints)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_27, _cache[3] || (_cache[3] = [
                              _createElementVNode("i", { class: "fs-s mr-xs ml-xs jirafficon jirafficon-plus" }, null, -1 /* HOISTED */),
                              _createTextVNode(" ポイント ")
                            ])))
                          : _createCommentVNode("v-if", true),
                        _cache[5] || (_cache[5] = _createTextVNode(" に変換 "))
                      ], 10 /* CLASS, PROPS */, _hoisted_26),
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("span", _hoisted_29, [
                          _cache[6] || (_cache[6] = _createElementVNode("i", { class: "fs-l mr-xs jirafficon jirafficon-coupon" }, null, -1 /* HOISTED */)),
                          _createTextVNode(" " + _toDisplayString(_ctx.convertPayoutCoupon), 1 /* TEXT */)
                        ]),
                        (_ctx.existsConvertablePoints)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                              _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fs-m mr-xs ml-xs text-gray jirafficon jirafficon-plus" }, null, -1 /* HOISTED */)),
                              _cache[8] || (_cache[8] = _createElementVNode("i", { class: "fs-m mr-xs jirafficon jirafficon-point" }, null, -1 /* HOISTED */)),
                              _createTextVNode(" " + _toDisplayString(_ctx.format(_ctx.lotteryResultPrize.convertable_points)) + " ポイント ", 1 /* TEXT */)
                            ]))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
  ]))
}