<template>
  <input
    type="submit"
    class="form__submit-btn"
    :value="value"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '送信',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
