import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["name", "value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_label = _resolveComponent("form-label")!
  const _component_select_box = _resolveComponent("select-box")!
  const _component_form_modal_selector_btn = _resolveComponent("form-modal-selector-btn")!
  const _component_form_control = _resolveComponent("form-control")!
  const _component_form_group = _resolveComponent("form-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.template.fields, (field, index) => {
      return (_openBlock(), _createBlock(_component_form_group, {
        key: _ctx.generateCustomAttributeKey(field.name),
        class: "form__group--column"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_form_label, { required: _ctx.required }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(field.label), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["required"]),
          _createElementVNode("input", {
            type: "hidden",
            name: _ctx.makeAtrrName(index),
            value: field.name
          }, null, 8 /* PROPS */, _hoisted_1),
          _createVNode(_component_form_control, null, {
            default: _withCtx(() => [
              (field.field_type === 'selectbox')
                ? (_openBlock(), _createBlock(_component_select_box, {
                    key: 0,
                    modelValue: _ctx.selectedAttribute[field.name],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.selectedAttribute[field.name]) = $event),
                    name: _ctx.makeAttrValue(index),
                    "selector-id": "item_custom_attribute",
                    "selector-class": _ctx.makeSelectedClass(field.name),
                    "selector-wrapper-class": _ctx.selectorWrapperClass
                  }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("option", { value: "" }, "---", -1 /* HOISTED */)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.options, ({ label, value }) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: value,
                          value: value
                        }, _toDisplayString(label), 9 /* TEXT, PROPS */, _hoisted_2))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "name", "selector-class", "selector-wrapper-class"]))
                : (field.field_type === 'radio')
                  ? (_openBlock(), _createBlock(_component_form_modal_selector_btn, {
                      key: 1,
                      name: field.name,
                      options: field.options
                    }, null, 8 /* PROPS */, ["name", "options"]))
                  : _createCommentVNode("v-if", true)
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}